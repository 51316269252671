/*
1. Prevent padding and border from affecting element width. (https://github.com/mozdevs/cssremedy/issues/4)
2. Allow adding a border to an element by just adding a border-width. (https://github.com/tailwindcss/tailwindcss/pull/116)
*/

*,
::before,
::after {
  box-sizing: border-box;
  /* 1 */
  border-width: 0;
  /* 2 */
  border-style: solid;
  /* 2 */
  border-color: #e5e7eb;
  /* 2 */
}

/* Exclude tables with border="1"  SITES-4121 AEM*/

table[border="1"],
table[border="1"] tr,
table[border="1"] th,
table[border="1"] td {
  border-width: initial;
  /* Reset the border for the table and its children */
  border-color: initial;
  border-collapse: collapse;
}

::before,
::after {
  --tw-content: "";
}

/*
1. Use a consistent sensible line-height in all browsers.
2. Prevent adjustments of font size after orientation changes in iOS.
3. Use a more readable tab size.
4. Use the user's configured `sans` font-family by default.
5. Use the user's configured `sans` font-feature-settings by default.
6. Use the user's configured `sans` font-variation-settings by default.
7. Disable tap highlights on iOS
*/

html,
:host {
  line-height: 1.5;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
  /* 3 */
  tab-size: 4;
  /* 3 */
  font-family: ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  /* 4 */
  font-feature-settings: "liga" off, "clig" off;
  /* 5 */
  font-variation-settings: normal;
  /* 6 */
  -webkit-tap-highlight-color: transparent;
  /* 7 */
}

*,
::before,
::after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

/*
Removes the default spacing and border for appropriate elements.
*/

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol,
ul,
menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

/*
Reset default styling for dialogs.
*/

dialog {
  padding: 0;
}

/*
Prevent resizing textareas horizontally by default.
*/

textarea {
  resize: vertical;
}

/*
1. Reset the default placeholder opacity in Firefox. (https://github.com/tailwindlabs/tailwindcss/issues/3300)
2. Set the default placeholder color to the user's configured gray 400 color.
*/

input::placeholder,
textarea::placeholder {
  opacity: 1;
  /* 1 */
  color: #9ca3af;
  /* 2 */
}

/*
Set the default cursor for buttons.
*/

button,
[role="button"] {
  cursor: pointer;
}

/*
Make sure disabled buttons don't get the pointer cursor.
*/

:disabled {
  cursor: default;
}

/*
1. Make replaced elements `display: block` by default. (https://github.com/mozdevs/cssremedy/issues/14)
2. Add `vertical-align: middle` to align replaced elements more sensibly by default. (https://github.com/jensimmons/cssremedy/issues/14#issuecomment-634934210)
   This can trigger a poorly considered lint error in some tools but is included by design.
*/

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
  /* 1 */
  vertical-align: middle;
  /* 2 */
}

/*
Constrain images and videos to the parent width and preserve their intrinsic aspect ratio. (https://github.com/mozdevs/cssremedy/issues/14)
*/

img,
video {
  max-width: 100%;
  height: auto;
}

/* Make elements with the HTML hidden attribute stay hidden by default */

[hidden] {
  display: none;
}

/* start- below style is for new PDP swatch text */

.glow-text-white {
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.8),
    /* Small bright glow */ 0 0 10px rgba(255, 255, 255, 0.6),
    /* Slightly larger glow */ 0 0 10px rgba(255, 255, 255, 0.4),
    /* Larger, softer glow */ 0 0 10px rgba(255, 255, 255, 0.2);
  /* Fainter, spread-out glow */
  color: white;
  /* Ensures text is bright */
}

.soft-black-shadow {
  color: darkgray;
  /* Black text */
  text-shadow: 0 0 20px rgba(255, 255, 255, 0.8),
    /* Small bright white glow */ 0 0 20px rgba(255, 255, 255, 0.6),
    /* Medium-sized softer glow */ 0 0 20px rgba(255, 255, 255, 0.4),
    /* Larger glow to spread out */ 0 0 20px rgba(255, 255, 255, 0.2);
}

.soft-gray-shadow {
  color: darkolivegreen;
  /* Black text */
  text-shadow: 0 0 20px rgba(255, 255, 255, 0.8),
    /* Small bright white glow */ 0 0 20px rgba(255, 255, 255, 0.6),
    /* Medium-sized softer glow */ 0 0 20px rgba(255, 255, 255, 0.4),
    /* Larger glow to spread out */ 0 0 20px rgba(255, 255, 255, 0.2);
}

/* end- below style is for new PDP swatch text */

/* 
@tailwind base;  // We have commented out this section of the code as it was impacting the CSS styles on the AEM site.
*/

.\!container {
  width: 100% !important;
}

.container {
  width: 100%;
}

@media (min-width: 326px) {
  .\!container {
    max-width: 326px !important;
  }

  .container {
    max-width: 326px;
  }
}

@media (min-width: 375px) {
  .\!container {
    max-width: 375px !important;
  }

  .container {
    max-width: 375px;
  }
}

@media (min-width: 640px) {
  .\!container {
    max-width: 640px !important;
  }

  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .\!container {
    max-width: 768px !important;
  }

  .container {
    max-width: 768px;
  }
}

@media (min-width: 801px) {
  .\!container {
    max-width: 801px !important;
  }

  .container {
    max-width: 801px;
  }
}

@media (min-width: 992px) {
  .\!container {
    max-width: 992px !important;
  }

  .container {
    max-width: 992px;
  }
}

@media (min-width: 1200px) {
  .\!container {
    max-width: 1200px !important;
  }

  .container {
    max-width: 1200px;
  }
}

@media (min-width: 1376px) {
  .\!container {
    max-width: 1376px !important;
  }

  .container {
    max-width: 1376px;
  }
}

@media (min-width: 1600px) {
  .\!container {
    max-width: 1600px !important;
  }

  .container {
    max-width: 1600px;
  }
}

.tailwind-typography-root {
  margin: 0px;
  display: block;
  overflow-wrap: break-word;
  font-size: 1rem;
  text-transform: uppercase;
}

.tailwind-typography-h1 {
  font-family: RHSans-UltraThin, Helvetica, Arial;
  font-size: 2.66667rem;
  font-weight: 300;
  line-height: 2.66667rem;
  letter-spacing: -0.02em;
  color: rgba(0, 0, 0, 1);
}

.tailwind-typography-h2 {
  font-family: RHSans-UltraThin, Helvetica, Arial;
  font-size: 2.25rem;
  font-weight: 300;
  line-height: 2.5833rem;
  letter-spacing: -0.02em;
  color: rgba(0, 0, 0, 1);
}

.tailwind-typography-h3 {
  font-family: RHSans-UltraThin, Helvetica, Arial;
  font-size: 1.66667rem;
  font-weight: 400;
  line-height: 1.91667rem;
  letter-spacing: -0.02em;
  color: rgba(0, 0, 0, 1);
}

.tailwind-typography-h4 {
  font-family: RHSans, Helvetica, Arial;
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 1.41667rem;
  letter-spacing: 0em;
  color: rgba(0, 0, 0, 1);
}

.tailwind-typography-h5 {
  font-family: RHSans, Helvetica, Arial;
  font-size: 1rem;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 1px;
  color: rgba(0, 0, 0, 1);
}

.tailwind-typography-h6 {
  font-family: CaslonRHThin, Big Caslon, Palatino;
  font-size: 1.16667rem;
  font-weight: 100;
  line-height: 20px;
  letter-spacing: 0em;
  color: rgba(0, 0, 0, 1);
}

@media (min-width: 1200px) {
  .tailwind-typography-h6 {
    font-size: 1.25rem;
  }
}

@media (min-width: 1600px) {
  .tailwind-typography-h6 {
    font-size: 1.33333rem;
    line-height: 1.33333rem;
  }
}

.tailwind-typography-body1 {
  font-family: RHSans-Thin, Helvetica, Arial;
  font-size: 1.08333rem;
  font-weight: 300;
  text-transform: none;
  line-height: 1.66667rem;
  letter-spacing: 0.01625rem;
  color: rgba(0, 0, 0, 1);
}

.tailwind-typography-body2 {
  font-family: RHSans-Roman, Helvetica, Arial;
  font-size: 1.08333rem;
  font-weight: 400;
  text-transform: none;
  line-height: 1.66667rem;
  letter-spacing: 0.015em;
  color: rgba(0, 0, 0, 1);
}

.tailwind-typography-subtitle1 {
  font-family: RHSans, Helvetica, Arial;
  font-size: 1.16667rem;
  font-weight: 400;
  line-height: 1.66667rem;
  letter-spacing: 0.5px;
  color: rgba(0, 0, 0, 1);
}

.tailwind-typography-subtitle2 {
  font-family: RHSans, Helvetica, Arial;
  font-size: 1.5rem;
  font-weight: 100;
  line-height: 1.83333rem;
  letter-spacing: 0.2px;
  color: rgba(0, 0, 0, 1);
}

.tailwind-typography-caption {
  overflow-wrap: break-word;
  font-family: RHSans-Light, Helvetica, Arial;
  font-size: 0.91667rem;
  font-weight: 300;
  text-transform: none;
  line-height: 1.083333rem;
  letter-spacing: 0.04em;
  color: rgba(0, 0, 0, 1);
}

.tailwind-typography-button {
  font-family: RHSans, Helvetica, Arial;
  font-size: 1rem;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1.5;
}

.tailwind-typography-overline {
  font-family: RHSans, Helvetica, Arial;
  font-size: 1rem;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 2.66;
}

.tailwind-typography-nowrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tailwind-button-base-style {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  font-family: RHSans, Helvetica, Arial;
  text-transform: uppercase;
}

.tailwind-button-base-style>span {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.tailwind-button-full-width {
  width: 100%;
}

#body-root .sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

#body-root .\!pointer-events-none {
  pointer-events: none !important;
}

#body-root .pointer-events-none {
  pointer-events: none;
}

#body-root .pointer-events-auto {
  pointer-events: auto;
}

#body-root .visible {
  visibility: visible;
}

#body-root .invisible {
  visibility: hidden;
}

#body-root .collapse {
  visibility: collapse;
}

#body-root .static {
  position: static;
}

#body-root .\!fixed {
  position: fixed !important;
}

#body-root .fixed {
  position: fixed;
}

#body-root .\!absolute {
  position: absolute !important;
}

#body-root .absolute {
  position: absolute;
}

#body-root .\!relative {
  position: relative !important;
}

#body-root .relative {
  position: relative;
}

#body-root .sticky {
  position: sticky;
}

#body-root .inset-0 {
  inset: 0px;
}

#body-root .inset-x-0 {
  left: 0px;
  right: 0px;
}

#body-root .inset-y-0 {
  top: 0px;
  bottom: 0px;
}

#body-root .\!bottom-0 {
  bottom: 0px !important;
}

#body-root .\!left-0 {
  left: 0px !important;
}

#body-root .\!left-\[-9999px\] {
  left: -9999px !important;
}

#body-root .\!right-0 {
  right: 0px !important;
}

#body-root .\!right-\[1px\] {
  right: 1px !important;
}

#body-root .\!top-0 {
  top: 0px !important;
}

#body-root .\!top-\[87px\] {
  top: 87px !important;
}

#body-root .\!top-\[initial\] {
  top: initial !important;
}

#body-root .-bottom-12 {
  bottom: -48px;
}

#body-root .-left-12 {
  left: -48px;
}

#body-root .-left-2 {
  left: -8px;
}

#body-root .-left-5 {
  left: -20px;
}

#body-root .-left-\[46px\] {
  left: -46px;
}

#body-root .-left-\[9999px\] {
  left: -9999px;
}

#body-root .-right-12 {
  right: -48px;
}

#body-root .-right-5 {
  right: -20px;
}

#body-root .-right-8 {
  right: -32px;
}

#body-root .-right-\[58px\] {
  right: -58px;
}

#body-root .-top-0 {
  top: -0px;
}

#body-root .-top-12 {
  top: -48px;
}

#body-root .-top-2 {
  top: -8px;
}

#body-root .-top-\[33px\] {
  top: -33px;
}

#body-root .-top-\[88px\] {
  top: -88px;
}

#body-root .bottom-0 {
  bottom: 0px;
}

#body-root .bottom-1 {
  bottom: 4px;
}

#body-root .bottom-2 {
  bottom: 8px;
}

#body-root .bottom-20 {
  bottom: 80px;
}

#body-root .bottom-7 {
  bottom: 28px;
}

#body-root .bottom-\[-8px\] {
  bottom: -8px;
}

#body-root .bottom-\[90px\] {
  bottom: 90px;
}

#body-root .bottom-auto {
  bottom: auto;
}

#body-root .left-0 {
  left: 0px;
}

#body-root .left-1\.5 {
  left: 6px;
}

#body-root .left-1\/2 {
  left: 50%;
}

#body-root .left-2 {
  left: 8px;
}

#body-root .left-\[-6px\] {
  left: -6px;
}

#body-root .left-\[1px\] {
  left: 1px;
}

#body-root .left-\[30\%\] {
  left: 30%;
}

#body-root .left-\[304px\] {
  left: 304px;
}

#body-root .left-\[60px\] {
  left: 60px;
}

#body-root .left-auto {
  left: auto;
}

#body-root .right-0 {
  right: 0px;
}

#body-root .right-1\.5 {
  right: 6px;
}

#body-root .right-2 {
  right: 8px;
}

#body-root .right-3 {
  right: 12px;
}

#body-root .right-4 {
  right: 16px;
}

#body-root .right-6 {
  right: 24px;
}

#body-root .right-auto {
  right: auto;
}

#body-root .top-0 {
  top: 0px;
}

#body-root .top-1\/2 {
  top: 50%;
}

#body-root .top-5 {
  top: 20px;
}

#body-root .top-6 {
  top: 24px;
}

#body-root .top-7 {
  top: 28px;
}

#body-root .top-\[-0\.2rem\] {
  top: -0.2rem;
}

#body-root .top-\[-2px\] {
  top: -2px;
}

#body-root .top-\[-5px\] {
  top: -5px;
}

#body-root .top-\[100px\] {
  top: 100px;
}

#body-root .top-\[149px\] {
  top: 149px;
}

#body-root .top-\[164px\] {
  top: 164px;
}

#body-root .top-\[1px\] {
  top: 1px;
}

#body-root .top-\[34px\] {
  top: 34px;
}

#body-root .top-\[36px\] {
  top: 36px;
}

#body-root .top-\[46\%\] {
  top: 46%;
}

#body-root .top-\[46px\] {
  top: 46px;
}

#body-root .top-\[522px\] {
  top: 522px;
}

#body-root .top-\[85\%\] {
  top: 85%;
}

#body-root .top-\[8px\] {
  top: 8px;
}

#body-root .top-full {
  top: 100%;
}

#body-root .isolate {
  isolation: isolate;
}

#body-root .\!z-\[999\] {
  z-index: 999 !important;
}

#body-root .z-0 {
  z-index: 0;
}

#body-root .z-10 {
  z-index: 10;
}

#body-root .z-20 {
  z-index: 20;
}

#body-root .z-40 {
  z-index: 40;
}

#body-root .z-50 {
  z-index: 50;
}

#body-root .z-\[1100\] {
  z-index: 1100;
}

#body-root .z-\[1110\] {
  z-index: 1110;
}

#body-root .z-\[11\] {
  z-index: 11;
}

#body-root .z-\[1200\] {
  z-index: 1200;
}

#body-root .z-\[1300\] {
  z-index: 1300;
}

#body-root .z-\[1\] {
  z-index: 1;
}

#body-root .z-\[2\] {
  z-index: 2;
}

#body-root .z-\[5\] {
  z-index: 5;
}

#body-root .z-\[99999\] {
  z-index: 99999;
}

#body-root .z-\[9999\] {
  z-index: 9999;
}

#body-root .z-\[99\] {
  z-index: 99;
}

#body-root .order-1 {
  order: 1;
}

#body-root .order-2 {
  order: 2;
}

#body-root .order-3 {
  order: 3;
}

#body-root .col-span-full {
  grid-column: 1 / -1;
}

#body-root .float-end {
  float: inline-end;
}

#body-root .float-right {
  float: right;
}

#body-root .\!m-0 {
  margin: 0px !important;
}

#body-root .\!m-\[-3px\] {
  margin: -3px !important;
}

#body-root .\!m-\[0_auto\] {
  margin: 0 auto !important;
}

#body-root .\!m-auto {
  margin: auto !important;
}

#body-root .m-0 {
  margin: 0px;
}

#body-root .m-1 {
  margin: 4px;
}

#body-root .m-2 {
  margin: 8px;
}

#body-root .m-4 {
  margin: 16px;
}

#body-root .m-\[0\.8rem\] {
  margin: 0.8rem;
}

#body-root .m-auto {
  margin: auto;
}

#body-root .\!mx-0 {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

#body-root .\!mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

#body-root .\!my-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

#body-root .\!my-5 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

#body-root .\!my-6 {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}

#body-root .\!my-\[11px\] {
  margin-top: 11px !important;
  margin-bottom: 11px !important;
}

#body-root .\!my-\[30px\] {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

#body-root .-mx-6 {
  margin-left: -24px;
  margin-right: -24px;
}

#body-root .mx-0 {
  margin-left: 0px;
  margin-right: 0px;
}

#body-root .mx-1 {
  margin-left: 4px;
  margin-right: 4px;
}

#body-root .mx-2\.5 {
  margin-left: 10px;
  margin-right: 10px;
}

#body-root .mx-\[56px\] {
  margin-left: 56px;
  margin-right: 56px;
}

#body-root .mx-auto {
  margin-left: auto;
  margin-right: auto;
}

#body-root .my-0 {
  margin-top: 0px;
  margin-bottom: 0px;
}

#body-root .my-1 {
  margin-top: 4px;
  margin-bottom: 4px;
}

#body-root .my-1\.5 {
  margin-top: 6px;
  margin-bottom: 6px;
}

#body-root .my-10 {
  margin-top: 40px;
  margin-bottom: 40px;
}

#body-root .my-2 {
  margin-top: 8px;
  margin-bottom: 8px;
}

#body-root .my-3 {
  margin-top: 12px;
  margin-bottom: 12px;
}

#body-root .my-4 {
  margin-top: 16px;
  margin-bottom: 16px;
}

#body-root .my-6 {
  margin-top: 24px;
  margin-bottom: 24px;
}

#body-root .my-8 {
  margin-top: 32px;
  margin-bottom: 32px;
}

#body-root .my-\[11px\] {
  margin-top: 11px;
  margin-bottom: 11px;
}

#body-root .my-\[12px\] {
  margin-top: 12px;
  margin-bottom: 12px;
}

#body-root .my-\[14px\] {
  margin-top: 14px;
  margin-bottom: 14px;
}

#body-root .my-\[25px\] {
  margin-top: 25px;
  margin-bottom: 25px;
}

#body-root .my-\[32px\] {
  margin-top: 32px;
  margin-bottom: 32px;
}

#body-root .my-\[40px\] {
  margin-top: 40px;
  margin-bottom: 40px;
}

#body-root .my-\[5px\] {
  margin-top: 5px;
  margin-bottom: 5px;
}

#body-root .my-\[6px\] {
  margin-top: 6px;
  margin-bottom: 6px;
}

#body-root .my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

#body-root .\!-mr-1\.5 {
  margin-right: -6px !important;
}

#body-root .\!-mt-\[8px\] {
  margin-top: -8px !important;
}

#body-root .\!mb-0 {
  margin-bottom: 0px !important;
}

#body-root .\!mb-1 {
  margin-bottom: 4px !important;
}

#body-root .\!mb-10 {
  margin-bottom: 40px !important;
}

#body-root .\!mb-12 {
  margin-bottom: 48px !important;
}

#body-root .\!mb-2 {
  margin-bottom: 8px !important;
}

#body-root .\!mb-2\.5 {
  margin-bottom: 10px !important;
}

#body-root .\!mb-3 {
  margin-bottom: 12px !important;
}

#body-root .\!mb-4 {
  margin-bottom: 16px !important;
}

#body-root .\!mb-5 {
  margin-bottom: 20px !important;
}

#body-root .\!mb-6 {
  margin-bottom: 24px !important;
}

#body-root .\!mb-9 {
  margin-bottom: 36px !important;
}

#body-root .\!mb-\[-8px\] {
  margin-bottom: -8px !important;
}

#body-root .\!mb-\[0px\] {
  margin-bottom: 0px !important;
}

#body-root .\!mb-\[12px\] {
  margin-bottom: 12px !important;
}

#body-root .\!mb-\[14px\] {
  margin-bottom: 14px !important;
}

#body-root .\!mb-\[16px\] {
  margin-bottom: 16px !important;
}

#body-root .\!mb-\[18px\] {
  margin-bottom: 18px !important;
}

#body-root .\!mb-\[2\.4px\] {
  margin-bottom: 2.4px !important;
}

#body-root .\!mb-\[34px\] {
  margin-bottom: 34px !important;
}

#body-root .\!mb-\[37px\] {
  margin-bottom: 37px !important;
}

#body-root .\!mb-\[3px\] {
  margin-bottom: 3px !important;
}

#body-root .\!mb-\[5px\] {
  margin-bottom: 5px !important;
}

#body-root .\!mb-\[60px\] {
  margin-bottom: 60px !important;
}

#body-root .\!mb-\[7px\] {
  margin-bottom: 7px !important;
}

#body-root .\!ml-0 {
  margin-left: 0px !important;
}

#body-root .\!ml-1 {
  margin-left: 4px !important;
}

#body-root .\!ml-2 {
  margin-left: 8px !important;
}

#body-root .\!ml-8 {
  margin-left: 32px !important;
}

#body-root .\!ml-\[-15px\] {
  margin-left: -15px !important;
}

#body-root .\!ml-\[-4px\] {
  margin-left: -4px !important;
}

#body-root .\!ml-\[15px\] {
  margin-left: 15px !important;
}

#body-root .\!ml-\[3px\] {
  margin-left: 3px !important;
}

#body-root .\!ml-\[6px\] {
  margin-left: 6px !important;
}

#body-root .\!ml-\[8px\] {
  margin-left: 8px !important;
}

#body-root .\!mr-0 {
  margin-right: 0px !important;
}

#body-root .\!mr-1 {
  margin-right: 4px !important;
}

#body-root .\!mr-1\.5 {
  margin-right: 6px !important;
}

#body-root .\!mr-10 {
  margin-right: 40px !important;
}

#body-root .\!mr-2 {
  margin-right: 8px !important;
}

#body-root .\!mr-3 {
  margin-right: 12px !important;
}

#body-root .\!mr-5 {
  margin-right: 20px !important;
}

#body-root .\!mr-7 {
  margin-right: 28px !important;
}

#body-root .\!mr-8 {
  margin-right: 32px !important;
}

#body-root .\!mr-9 {
  margin-right: 36px !important;
}

#body-root .\!mr-\[16px\] {
  margin-right: 16px !important;
}

#body-root .\!mr-\[30px\] {
  margin-right: 30px !important;
}

#body-root .\!mr-\[36px\] {
  margin-right: 36px !important;
}

#body-root .\!mr-\[4px\] {
  margin-right: 4px !important;
}

#body-root .\!mr-\[5px\] {
  margin-right: 5px !important;
}

#body-root .\!mr-\[8px\] {
  margin-right: 8px !important;
}

#body-root .\!ms-\[440px\] {
  margin-inline-start: 440px !important;
}

#body-root .\!mt-0 {
  margin-top: 0px !important;
}

#body-root .\!mt-2 {
  margin-top: 8px !important;
}

#body-root .\!mt-2\.5 {
  margin-top: 10px !important;
}

#body-root .\!mt-3 {
  margin-top: 12px !important;
}

#body-root .\!mt-4 {
  margin-top: 16px !important;
}

#body-root .\!mt-6 {
  margin-top: 24px !important;
}

#body-root .\!mt-\[-4px\] {
  margin-top: -4px !important;
}

#body-root .\!mt-\[0px\] {
  margin-top: 0px !important;
}

#body-root .\!mt-\[17px\] {
  margin-top: 17px !important;
}

#body-root .\!mt-\[24px\] {
  margin-top: 24px !important;
}

#body-root .\!mt-\[30px\] {
  margin-top: 30px !important;
}

#body-root .\!mt-\[32px\] {
  margin-top: 32px !important;
}

#body-root .\!mt-\[37px\] {
  margin-top: 37px !important;
}

#body-root .\!mt-\[3px\] {
  margin-top: 3px !important;
}

#body-root .\!mt-\[5px\] {
  margin-top: 5px !important;
}

#body-root .\!mt-\[8px\] {
  margin-top: 8px !important;
}

#body-root .-ml-4 {
  margin-left: -16px;
}

#body-root .-ml-\[2px\] {
  margin-left: -2px;
}

#body-root .-mt-0\.5 {
  margin-top: -2px;
}

#body-root .-mt-1 {
  margin-top: -4px;
}

#body-root .-mt-4 {
  margin-top: -16px;
}

#body-root .-mt-5 {
  margin-top: -20px;
}

#body-root .-mt-\[150px\] {
  margin-top: -150px;
}

#body-root .-mt-\[34px\] {
  margin-top: -34px;
}

#body-root .mb-0 {
  margin-bottom: 0px;
}

#body-root .mb-1 {
  margin-bottom: 4px;
}

#body-root .mb-1\.5 {
  margin-bottom: 6px;
}

#body-root .mb-10 {
  margin-bottom: 40px;
}

#body-root .mb-12 {
  margin-bottom: 48px;
}

#body-root .mb-16 {
  margin-bottom: 64px;
}

#body-root .mb-2 {
  margin-bottom: 8px;
}

#body-root .mb-2\.5 {
  margin-bottom: 10px;
}

#body-root .mb-3 {
  margin-bottom: 12px;
}

#body-root .mb-4 {
  margin-bottom: 16px;
}

#body-root .mb-5 {
  margin-bottom: 20px;
}

#body-root .mb-6 {
  margin-bottom: 24px;
}

#body-root .mb-8 {
  margin-bottom: 32px;
}

#body-root .mb-9 {
  margin-bottom: 36px;
}

#body-root .mb-\[0\.35em\] {
  margin-bottom: 0.35em;
}

#body-root .mb-\[10px\] {
  margin-bottom: 10px;
}

#body-root .mb-\[12px\] {
  margin-bottom: 12px;
}

#body-root .mb-\[13px\] {
  margin-bottom: 13px;
}

#body-root .mb-\[16px\] {
  margin-bottom: 16px;
}

#body-root .mb-\[17px\] {
  margin-bottom: 17px;
}

#body-root .mb-\[20px\] {
  margin-bottom: 20px;
}

#body-root .mb-\[21px\] {
  margin-bottom: 21px;
}

#body-root .mb-\[24px\] {
  margin-bottom: 24px;
}

#body-root .mb-\[2px\] {
  margin-bottom: 2px;
}

#body-root .mb-\[30px\] {
  margin-bottom: 30px;
}

#body-root .mb-\[34\.5px\] {
  margin-bottom: 34.5px;
}

#body-root .mb-\[3px\] {
  margin-bottom: 3px;
}

#body-root .mb-\[56px\] {
  margin-bottom: 56px;
}

#body-root .mb-\[5px\] {
  margin-bottom: 5px;
}

#body-root .mb-\[8px\] {
  margin-bottom: 8px;
}

#body-root .ml-0 {
  margin-left: 0px;
}

#body-root .ml-1 {
  margin-left: 4px;
}

#body-root .ml-10 {
  margin-left: 40px;
}

#body-root .ml-2 {
  margin-left: 8px;
}

#body-root .ml-4 {
  margin-left: 16px;
}

#body-root .ml-8 {
  margin-left: 32px;
}

#body-root .ml-\[-143px\] {
  margin-left: -143px;
}

#body-root .ml-\[14px\] {
  margin-left: 14px;
}

#body-root .ml-\[2px\] {
  margin-left: 2px;
}

#body-root .ml-\[4px\] {
  margin-left: 4px;
}

#body-root .ml-\[50px\] {
  margin-left: 50px;
}

#body-root .ml-\[5px\] {
  margin-left: 5px;
}

#body-root .ml-\[79px\] {
  margin-left: 79px;
}

#body-root .ml-\[8px\] {
  margin-left: 8px;
}

#body-root .ml-auto {
  margin-left: auto;
}

#body-root .mr-0 {
  margin-right: 0px;
}

#body-root .mr-0\.5 {
  margin-right: 2px;
}

#body-root .mr-1 {
  margin-right: 4px;
}

#body-root .mr-10 {
  margin-right: 40px;
}

#body-root .mr-2 {
  margin-right: 8px;
}

#body-root .mr-3 {
  margin-right: 12px;
}

#body-root .mr-5 {
  margin-right: 20px;
}

#body-root .mr-\[-12px\] {
  margin-right: -12px;
}

#body-root .mr-\[0\.25em\] {
  margin-right: 0.25em;
}

#body-root .mr-\[10px\] {
  margin-right: 10px;
}

#body-root .mr-\[15px\] {
  margin-right: 15px;
}

#body-root .mr-\[20px\] {
  margin-right: 20px;
}

#body-root .mr-\[28px\] {
  margin-right: 28px;
}

#body-root .mr-\[2px\] {
  margin-right: 2px;
}

#body-root .mr-\[30px\] {
  margin-right: 30px;
}

#body-root .mr-\[4px\] {
  margin-right: 4px;
}

#body-root .mr-\[6px\] {
  margin-right: 6px;
}

#body-root .mr-\[8px\] {
  margin-right: 8px;
}

#body-root .mt-0 {
  margin-top: 0px;
}

#body-root .mt-1 {
  margin-top: 4px;
}

#body-root .mt-1\.5 {
  margin-top: 6px;
}

#body-root .mt-10 {
  margin-top: 40px;
}

#body-root .mt-12 {
  margin-top: 48px;
}

#body-root .mt-2 {
  margin-top: 8px;
}

#body-root .mt-2\.5 {
  margin-top: 10px;
}

#body-root .mt-3 {
  margin-top: 12px;
}

#body-root .mt-3\.5 {
  margin-top: 14px;
}

#body-root .mt-4 {
  margin-top: 16px;
}

#body-root .mt-5 {
  margin-top: 20px;
}

#body-root .mt-6 {
  margin-top: 24px;
}

#body-root .mt-7 {
  margin-top: 28px;
}

#body-root .mt-8 {
  margin-top: 32px;
}

#body-root .mt-\[-15px\] {
  margin-top: -15px;
}

#body-root .mt-\[-8px\] {
  margin-top: -8px;
}

#body-root .mt-\[10px\] {
  margin-top: 10px;
}

#body-root .mt-\[12px\] {
  margin-top: 12px;
}

#body-root .mt-\[13px\] {
  margin-top: 13px;
}

#body-root .mt-\[16px\] {
  margin-top: 16px;
}

#body-root .mt-\[17px\] {
  margin-top: 17px;
}

#body-root .mt-\[20px\] {
  margin-top: 20px;
}

#body-root .mt-\[22px\] {
  margin-top: 22px;
}

#body-root .mt-\[24px\] {
  margin-top: 24px;
}

#body-root .mt-\[2px\] {
  margin-top: 2px;
}

#body-root .mt-\[30px\] {
  margin-top: 30px;
}

#body-root .mt-\[32px\] {
  margin-top: 32px;
}

#body-root .mt-\[35px\] {
  margin-top: 35px;
}

#body-root .mt-\[3px\] {
  margin-top: 3px;
}

#body-root .mt-\[40px\] {
  margin-top: 40px;
}

#body-root .mt-\[4px\] {
  margin-top: 4px;
}

#body-root .mt-\[5px\] {
  margin-top: 5px;
}

#body-root .mt-\[68px\] {
  margin-top: 68px;
}

#body-root .mt-\[6px\] {
  margin-top: 6px;
}

#body-root .mt-\[7px\] {
  margin-top: 7px;
}

#body-root .mt-\[9px\] {
  margin-top: 9px;
}

#body-root .mt-auto {
  margin-top: auto;
}

#body-root .\!box-border {
  box-sizing: border-box !important;
}

#body-root .box-border {
  box-sizing: border-box;
}

#body-root .\!line-clamp-1 {
  overflow: hidden !important;
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: 1 !important;
}

#body-root .line-clamp-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

#body-root .\!block {
  display: block !important;
}

#body-root .block {
  display: block;
}

#body-root .\!inline-block {
  display: inline-block !important;
}

#body-root .inline-block {
  display: inline-block;
}

#body-root .inline {
  display: inline;
}

#body-root .\!flex {
  display: flex !important;
}

#body-root .flex {
  display: flex;
}

#body-root .inline-flex {
  display: inline-flex;
}

#body-root .table {
  display: table;
}

#body-root .table-column {
  display: table-column;
}

#body-root .\!grid {
  display: grid !important;
}

#body-root .grid {
  display: grid;
}

#body-root .inline-grid {
  display: inline-grid;
}

#body-root .contents {
  display: contents;
}

#body-root .\!list-item {
  display: list-item !important;
}

#body-root .list-item {
  display: list-item;
}

#body-root .hidden {
  display: none;
}

#body-root .aspect-\[1\.4860\] {
  aspect-ratio: 1.4860;
}

#body-root .aspect-\[1\.8\] {
  aspect-ratio: 1.8;
}

#body-root .aspect-\[2\/1\] {
  aspect-ratio: 2/1;
}

#body-root .aspect-square {
  aspect-ratio: 1 / 1;
}

#body-root .size-8 {
  width: 32px;
  height: 32px;
}

#body-root .\!h-10 {
  height: 40px !important;
}

#body-root .\!h-12 {
  height: 48px !important;
}

#body-root .\!h-14 {
  height: 56px !important;
}

#body-root .\!h-2 {
  height: 8px !important;
}

#body-root .\!h-20 {
  height: 80px !important;
}

#body-root .\!h-3 {
  height: 12px !important;
}

#body-root .\!h-4 {
  height: 16px !important;
}

#body-root .\!h-5 {
  height: 20px !important;
}

#body-root .\!h-8 {
  height: 32px !important;
}

#body-root .\!h-\[0\.03rem\] {
  height: 0.03rem !important;
}

#body-root .\!h-\[0\.5px\] {
  height: 0.5px !important;
}

#body-root .\!h-\[10px\] {
  height: 10px !important;
}

#body-root .\!h-\[136px\] {
  height: 136px !important;
}

#body-root .\!h-\[14px\] {
  height: 14px !important;
}

#body-root .\!h-\[15px\] {
  height: 15px !important;
}

#body-root .\!h-\[16px\] {
  height: 16px !important;
}

#body-root .\!h-\[18px\] {
  height: 18px !important;
}

#body-root .\!h-\[2\.45rem\] {
  height: 2.45rem !important;
}

#body-root .\!h-\[20px\] {
  height: 20px !important;
}

#body-root .\!h-\[22px\] {
  height: 22px !important;
}

#body-root .\!h-\[24px\] {
  height: 24px !important;
}

#body-root .\!h-\[260px\] {
  height: 260px !important;
}

#body-root .\!h-\[26px\] {
  height: 26px !important;
}

#body-root .\!h-\[2px\] {
  height: 2px !important;
}

#body-root .\!h-\[46px\] {
  height: 46px !important;
}

#body-root .\!h-\[48px\] {
  height: 48px !important;
}

#body-root .\!h-\[56px\] {
  height: 56px !important;
}

#body-root .\!h-\[68px\] {
  height: 68px !important;
}

#body-root .\!h-\[7px\] {
  height: 7px !important;
}

#body-root .\!h-\[85px\] {
  height: 85px !important;
}

#body-root .\!h-\[95px\] {
  height: 95px !important;
}

#body-root .\!h-auto {
  height: auto !important;
}

#body-root .\!h-fit {
  height: -moz-fit-content !important;
  height: fit-content !important;
}

#body-root .\!h-full {
  height: 100% !important;
}

#body-root .h-10 {
  height: 40px;
}

#body-root .h-11 {
  height: 44px;
}

#body-root .h-12 {
  height: 48px;
}

#body-root .h-14 {
  height: 56px;
}

#body-root .h-16 {
  height: 64px;
}

#body-root .h-2 {
  height: 8px;
}

#body-root .h-3 {
  height: 12px;
}

#body-root .h-32 {
  height: 128px;
}

#body-root .h-4 {
  height: 16px;
}

#body-root .h-4\/5 {
  height: 80%;
}

#body-root .h-40 {
  height: 160px;
}

#body-root .h-5 {
  height: 20px;
}

#body-root .h-56 {
  height: 224px;
}

#body-root .h-6 {
  height: 24px;
}

#body-root .h-60 {
  height: 240px;
}

#body-root .h-7 {
  height: 28px;
}

#body-root .h-8 {
  height: 32px;
}

#body-root .h-9 {
  height: 36px;
}

#body-root .h-\[0\.5px\] {
  height: 0.5px;
}

#body-root .h-\[1\.125rem\] {
  height: 1.125rem;
}

#body-root .h-\[1\.43em\] {
  height: 1.43em;
}

#body-root .h-\[100\%\] {
  height: 100%;
}

#body-root .h-\[104px\] {
  height: 104px;
}

#body-root .h-\[108px\] {
  height: 108px;
}

#body-root .h-\[136px\] {
  height: 136px;
}

#body-root .h-\[13px\] {
  height: 13px;
}

#body-root .h-\[14\.4px\] {
  height: 14.4px;
}

#body-root .h-\[14px\] {
  height: 14px;
}

#body-root .h-\[15\%\] {
  height: 15%;
}

#body-root .h-\[15px\] {
  height: 15px;
}

#body-root .h-\[160px\] {
  height: 160px;
}

#body-root .h-\[16px\] {
  height: 16px;
}

#body-root .h-\[17px\] {
  height: 17px;
}

#body-root .h-\[18px\] {
  height: 18px;
}

#body-root .h-\[1px\] {
  height: 1px;
}

#body-root .h-\[200px\] {
  height: 200px;
}

#body-root .h-\[20px\] {
  height: 20px;
}

#body-root .h-\[21px\] {
  height: 21px;
}

#body-root .h-\[22px\] {
  height: 22px;
}

#body-root .h-\[232px\] {
  height: 232px;
}

#body-root .h-\[23px\] {
  height: 23px;
}

#body-root .h-\[25px\] {
  height: 25px;
}

#body-root .h-\[260px\] {
  height: 260px;
}

#body-root .h-\[26px\] {
  height: 26px;
}

#body-root .h-\[29px\] {
  height: 29px;
}

#body-root .h-\[30px\] {
  height: 30px;
}

#body-root .h-\[32px\] {
  height: 32px;
}

#body-root .h-\[342px\] {
  height: 342px;
}

#body-root .h-\[348px\] {
  height: 348px;
}

#body-root .h-\[34px\] {
  height: 34px;
}

#body-root .h-\[3px\] {
  height: 3px;
}

#body-root .h-\[410px\] {
  height: 410px;
}

#body-root .h-\[42px\] {
  height: 42px;
}

#body-root .h-\[46px\] {
  height: 46px;
}

#body-root .h-\[48px\] {
  height: 48px;
}

#body-root .h-\[4px\] {
  height: 4px;
}

#body-root .h-\[54px\] {
  height: 54px;
}

#body-root .h-\[57px\] {
  height: 57px;
}

#body-root .h-\[68px\] {
  height: 68px;
}

#body-root .h-\[75\%\] {
  height: 75%;
}

#body-root .h-\[77px\] {
  height: 77px;
}

#body-root .h-\[786px\] {
  height: 786px;
}

#body-root .h-\[800px\] {
  height: 800px;
}

#body-root .h-\[80px\] {
  height: 80px;
}

#body-root .h-\[84px\] {
  height: 84px;
}

#body-root .h-\[85px\] {
  height: 85px;
}

#body-root .h-\[88px\] {
  height: 88px;
}

#body-root .h-\[8px\] {
  height: 8px;
}

#body-root .h-\[97px\] {
  height: 97px;
}

#body-root .h-\[calc\(100vh-195px\)\] {
  height: calc(100vh - 195px);
}

#body-root .h-auto {
  height: auto;
}

#body-root .h-fit {
  height: -moz-fit-content;
  height: fit-content;
}

#body-root .h-full {
  height: 100%;
}

#body-root .h-screen {
  height: 100vh;
}

#body-root .\!max-h-\[64px\] {
  max-height: 64px !important;
}

#body-root .max-h-0 {
  max-height: 0px;
}

#body-root .max-h-12 {
  max-height: 48px;
}

#body-root .max-h-14 {
  max-height: 56px;
}

#body-root .max-h-80 {
  max-height: 320px;
}

#body-root .max-h-\[500px\] {
  max-height: 500px;
}

#body-root .max-h-\[650px\] {
  max-height: 650px;
}

#body-root .max-h-full {
  max-height: 100%;
}

#body-root .\!min-h-\[24px\] {
  min-height: 24px !important;
}

#body-root .\!min-h-\[33px\] {
  min-height: 33px !important;
}

#body-root .\!min-h-full {
  min-height: 100% !important;
}

#body-root .min-h-1 {
  min-height: 4px;
}

#body-root .min-h-4 {
  min-height: 16px;
}

#body-root .min-h-6 {
  min-height: 24px;
}

#body-root .min-h-\[100px\] {
  min-height: 100px;
}

#body-root .min-h-\[136px\] {
  min-height: 136px;
}

#body-root .min-h-\[18px\] {
  min-height: 18px;
}

#body-root .min-h-\[48px\] {
  min-height: 48px;
}

#body-root .min-h-\[793px\] {
  min-height: 793px;
}

#body-root .\!w-10 {
  width: 40px !important;
}

#body-root .\!w-2 {
  width: 8px !important;
}

#body-root .\!w-3 {
  width: 12px !important;
}

#body-root .\!w-32 {
  width: 128px !important;
}

#body-root .\!w-4 {
  width: 16px !important;
}

#body-root .\!w-40 {
  width: 160px !important;
}

#body-root .\!w-8 {
  width: 32px !important;
}

#body-root .\!w-\[100px\] {
  width: 100px !important;
}

#body-root .\!w-\[1094px\] {
  width: 1094px !important;
}

#body-root .\!w-\[11px\] {
  width: 11px !important;
}

#body-root .\!w-\[159px\] {
  width: 159px !important;
}

#body-root .\!w-\[16px\] {
  width: 16px !important;
}

#body-root .\!w-\[18px\] {
  width: 18px !important;
}

#body-root .\!w-\[2\.35rem\] {
  width: 2.35rem !important;
}

#body-root .\!w-\[20px\] {
  width: 20px !important;
}

#body-root .\!w-\[215px\] {
  width: 215px !important;
}

#body-root .\!w-\[232px\] {
  width: 232px !important;
}

#body-root .\!w-\[240px\] {
  width: 240px !important;
}

#body-root .\!w-\[26px\] {
  width: 26px !important;
}

#body-root .\!w-\[311px\] {
  width: 311px !important;
}

#body-root .\!w-\[32px\] {
  width: 32px !important;
}

#body-root .\!w-\[343px\] {
  width: 343px !important;
}

#body-root .\!w-\[3px\] {
  width: 3px !important;
}

#body-root .\!w-\[42px\] {
  width: 42px !important;
}

#body-root .\!w-\[48px\] {
  width: 48px !important;
}

#body-root .\!w-\[85\%\] {
  width: 85% !important;
}

#body-root .\!w-\[86px\] {
  width: 86px !important;
}

#body-root .\!w-auto {
  width: auto !important;
}

#body-root .\!w-full {
  width: 100% !important;
}

#body-root .w-1\/2 {
  width: 50%;
}

#body-root .w-1\/3 {
  width: 33.333333%;
}

#body-root .w-10 {
  width: 40px;
}

#body-root .w-11 {
  width: 44px;
}

#body-root .w-12 {
  width: 48px;
}

#body-root .w-14 {
  width: 56px;
}

#body-root .w-16 {
  width: 64px;
}

#body-root .w-2 {
  width: 8px;
}

#body-root .w-28 {
  width: 112px;
}

#body-root .w-3 {
  width: 12px;
}

#body-root .w-4 {
  width: 16px;
}

#body-root .w-5 {
  width: 20px;
}

#body-root .w-6 {
  width: 24px;
}

#body-root .w-64 {
  width: 256px;
}

#body-root .w-7 {
  width: 28px;
}

#body-root .w-8 {
  width: 32px;
}

#body-root .w-80 {
  width: 320px;
}

#body-root .w-9 {
  width: 36px;
}

#body-root .w-\[100\%\] {
  width: 100%;
}

#body-root .w-\[104px\] {
  width: 104px;
}

#body-root .w-\[106px\] {
  width: 106px;
}

#body-root .w-\[113px\] {
  width: 113px;
}

#body-root .w-\[122px\] {
  width: 122px;
}

#body-root .w-\[125\.5px\] {
  width: 125.5px;
}

#body-root .w-\[125\.6px\] {
  width: 125.6px;
}

#body-root .w-\[130px\] {
  width: 130px;
}

#body-root .w-\[134px\] {
  width: 134px;
}

#body-root .w-\[143px\] {
  width: 143px;
}

#body-root .w-\[149px\] {
  width: 149px;
}

#body-root .w-\[14px\] {
  width: 14px;
}

#body-root .w-\[150px\] {
  width: 150px;
}

#body-root .w-\[152px\] {
  width: 152px;
}

#body-root .w-\[158px\] {
  width: 158px;
}

#body-root .w-\[160px\] {
  width: 160px;
}

#body-root .w-\[165px\] {
  width: 165px;
}

#body-root .w-\[16px\] {
  width: 16px;
}

#body-root .w-\[173px\] {
  width: 173px;
}

#body-root .w-\[178px\] {
  width: 178px;
}

#body-root .w-\[183px\] {
  width: 183px;
}

#body-root .w-\[188px\] {
  width: 188px;
}

#body-root .w-\[200px\] {
  width: 200px;
}

#body-root .w-\[216px\] {
  width: 216px;
}

#body-root .w-\[218px\] {
  width: 218px;
}

#body-root .w-\[21px\] {
  width: 21px;
}

#body-root .w-\[220px\] {
  width: 220px;
}

#body-root .w-\[222px\] {
  width: 222px;
}

#body-root .w-\[22px\] {
  width: 22px;
}

#body-root .w-\[237px\] {
  width: 237px;
}

#body-root .w-\[265px\] {
  width: 265px;
}

#body-root .w-\[270px\] {
  width: 270px;
}

#body-root .w-\[272px\] {
  width: 272px;
}

#body-root .w-\[275px\] {
  width: 275px;
}

#body-root .w-\[290px\] {
  width: 290px;
}

#body-root .w-\[296px\] {
  width: 296px;
}

#body-root .w-\[30\%\] {
  width: 30%;
}

#body-root .w-\[300px\] {
  width: 300px;
}

#body-root .w-\[311px\] {
  width: 311px;
}

#body-root .w-\[320px\] {
  width: 320px;
}

#body-root .w-\[340px\] {
  width: 340px;
}

#body-root .w-\[343px\] {
  width: 343px;
}

#body-root .w-\[347px\] {
  width: 347px;
}

#body-root .w-\[380px\] {
  width: 380px;
}

#body-root .w-\[40\%\] {
  width: 40%;
}

#body-root .w-\[400px\] {
  width: 400px;
}

#body-root .w-\[403px\] {
  width: 403px;
}

#body-root .w-\[40px\] {
  width: 40px;
}

#body-root .w-\[45px\] {
  width: 45px;
}

#body-root .w-\[473px\] {
  width: 473px;
}

#body-root .w-\[48px\] {
  width: 48px;
}

#body-root .w-\[50\%\] {
  width: 50%;
}

#body-root .w-\[516px\] {
  width: 516px;
}

#body-root .w-\[60\%\] {
  width: 60%;
}

#body-root .w-\[680px\] {
  width: 680px;
}

#body-root .w-\[68px\] {
  width: 68px;
}

#body-root .w-\[75vw\] {
  width: 75vw;
}

#body-root .w-\[77px\] {
  width: 77px;
}

#body-root .w-\[80\%\] {
  width: 80%;
}

#body-root .w-\[80px\] {
  width: 80px;
}

#body-root .w-\[81\%\] {
  width: 81%;
}

#body-root .w-\[82\%\] {
  width: 82%;
}

#body-root .w-\[86px\] {
  width: 86px;
}

#body-root .w-\[87\%\] {
  width: 87%;
}

#body-root .w-\[90px\] {
  width: 90px;
}

#body-root .w-\[98\%\] {
  width: 98%;
}

#body-root .w-\[calc\(100\%-24px\)\] {
  width: calc(100% - 24px);
}

#body-root .w-\[calc\(100\%-32px\)\] {
  width: calc(100% - 32px);
}

#body-root .w-\[calc\(100\%-60px\)\] {
  width: calc(100% - 60px);
}

#body-root .w-auto {
  width: auto;
}

#body-root .w-fill-available {
  width: -webkit-fill-available;
}

#body-root .w-fit {
  width: -moz-fit-content;
  width: fit-content;
}

#body-root .w-full {
  width: 100%;
}

#body-root .w-max {
  width: max-content;
}

#body-root .w-screen {
  width: 100vw;
}

#body-root .w-unset {
  width: unset;
}

#body-root .w-w-407 {
  width: 407px;
}

#body-root .w-w-734 {
  width: 734px;
}

#body-root .\!min-w-0 {
  min-width: 0px !important;
}

#body-root .min-w-0 {
  min-width: 0px;
}

#body-root .min-w-24 {
  min-width: 96px;
}

#body-root .min-w-4 {
  min-width: 16px;
}

#body-root .min-w-\[114px\] {
  min-width: 114px;
}

#body-root .min-w-\[120px\] {
  min-width: 120px;
}

#body-root .min-w-\[174px\] {
  min-width: 174px;
}

#body-root .min-w-\[256px\] {
  min-width: 256px;
}

#body-root .min-w-\[270px\] {
  min-width: 270px;
}

#body-root .min-w-\[300px\] {
  min-width: 300px;
}

#body-root .min-w-\[45px\] {
  min-width: 45px;
}

#body-root .min-w-\[642px\] {
  min-width: 642px;
}

#body-root .min-w-\[64px\] {
  min-width: 64px;
}

#body-root .min-w-\[66px\] {
  min-width: 66px;
}

#body-root .min-w-\[90px\] {
  min-width: 90px;
}

#body-root .\!max-w-\[1920px\] {
  max-width: 1920px !important;
}

#body-root .\!max-w-\[600px\] {
  max-width: 600px !important;
}

#body-root .\!max-w-\[64px\] {
  max-width: 64px !important;
}

#body-root .\!max-w-\[741px\] {
  max-width: 741px !important;
}

#body-root .\!max-w-\[74px\] {
  max-width: 74px !important;
}

#body-root .max-w-11 {
  max-width: 44px;
}

#body-root .max-w-12 {
  max-width: 48px;
}

#body-root .max-w-16 {
  max-width: 64px;
}

#body-root .max-w-\[114px\] {
  max-width: 114px;
}

#body-root .max-w-\[1150px\] {
  max-width: 1150px;
}

#body-root .max-w-\[1200px\] {
  max-width: 1200px;
}

#body-root .max-w-\[139px\] {
  max-width: 139px;
}

#body-root .max-w-\[152px\] {
  max-width: 152px;
}

#body-root .max-w-\[184px\] {
  max-width: 184px;
}

#body-root .max-w-\[1920px\] {
  max-width: 1920px;
}

#body-root .max-w-\[240px\] {
  max-width: 240px;
}

#body-root .max-w-\[291px\] {
  max-width: 291px;
}

#body-root .max-w-\[305px\] {
  max-width: 305px;
}

#body-root .max-w-\[310px\] {
  max-width: 310px;
}

#body-root .max-w-\[343px\] {
  max-width: 343px;
}

#body-root .max-w-\[350px\] {
  max-width: 350px;
}

#body-root .max-w-\[376px\] {
  max-width: 376px;
}

#body-root .max-w-\[460px\] {
  max-width: 460px;
}

#body-root .max-w-\[50\%\] {
  max-width: 50%;
}

#body-root .max-w-\[78px\] {
  max-width: 78px;
}

#body-root .max-w-\[90\%\] {
  max-width: 90%;
}

#body-root .max-w-\[90px\] {
  max-width: 90px;
}

#body-root .max-w-full {
  max-width: 100%;
}

#body-root .max-w-none {
  max-width: none;
}

#body-root .\!flex-1 {
  flex: 1 1 0% !important;
}

#body-root .flex-1 {
  flex: 1 1 0%;
}

#body-root .flex-\[0_0_100\%\] {
  flex: 0 0 100%;
}

#body-root .flex-auto {
  flex: 1 1 auto;
}

#body-root .flex-none {
  flex: none;
}

#body-root .\!flex-shrink-0 {
  flex-shrink: 0 !important;
}

#body-root .flex-shrink-0 {
  flex-shrink: 0;
}

#body-root .shrink {
  flex-shrink: 1;
}

#body-root .shrink-0 {
  flex-shrink: 0;
}

#body-root .flex-grow {
  flex-grow: 1;
}

#body-root .flex-grow-0 {
  flex-grow: 0;
}

#body-root .\!grow {
  flex-grow: 1 !important;
}

#body-root .grow {
  flex-grow: 1;
}

#body-root .grow-0 {
  flex-grow: 0;
}

#body-root .\!basis-1\/3 {
  flex-basis: 33.333333% !important;
}

#body-root .\!basis-auto {
  flex-basis: auto !important;
}

#body-root .basis-0 {
  flex-basis: 0px;
}

#body-root .basis-\[27\.5\%\] {
  flex-basis: 27.5%;
}

#body-root .basis-\[45\%\] {
  flex-basis: 45%;
}

#body-root .basis-\[65\%\] {
  flex-basis: 65%;
}

#body-root .basis-full {
  flex-basis: 100%;
}

#body-root .table-auto {
  table-layout: auto;
}

#body-root .-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

#body-root .-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

#body-root .-translate-y-\[4\%\] {
  --tw-translate-y: -4%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

#body-root .translate-x-full {
  --tw-translate-x: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

#body-root .translate-y-0 {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

#body-root .translate-y-\[52\%\] {
  --tw-translate-y: 52%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

#body-root .translate-y-\[calc\(100\%-36px\)\] {
  --tw-translate-y: calc(100% - 36px);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

#body-root .-rotate-90 {
  --tw-rotate: -90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

#body-root .rotate-0 {
  --tw-rotate: 0deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

#body-root .rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

#body-root .rotate-45 {
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

#body-root .rotate-90 {
  --tw-rotate: 90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

#body-root .scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

#body-root .scale-90 {
  --tw-scale-x: .9;
  --tw-scale-y: .9;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

#body-root .scale-x-100 {
  --tw-scale-x: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

#body-root .scale-y-\[60\%\] {
  --tw-scale-y: 60%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

#body-root .transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

#body-root .\!transform-none {
  transform: none !important;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

#body-root .animate-PDPSwatchCarouselfadeIn {
  animation: fadeIn 500ms forwards;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

#body-root .animate-PDPSwatchCarouselfadeOut {
  animation: fadeOut 500ms forwards;
}

#body-root .animate-\[fadeInUp_0s_ease-in_0s_1_normal_none_running\] {
  animation: fadeInUp 0s ease-in 0s 1 normal none running;
}

#body-root .animate-none {
  animation: none;
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

#body-root .animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

#body-root .\!cursor-default {
  cursor: default !important;
}

#body-root .\!cursor-pointer {
  cursor: pointer !important;
}

#body-root .cursor-default {
  cursor: default;
}

#body-root .cursor-not-allowed {
  cursor: not-allowed;
}

#body-root .cursor-pointer {
  cursor: pointer;
}

#body-root .select-none {
  -webkit-user-select: none;
          user-select: none;
}

#body-root .resize {
  resize: both;
}

#body-root .list-\[initial\] {
  list-style-type: initial;
}

#body-root .list-disc {
  list-style-type: disc;
}

#body-root .list-none {
  list-style-type: none;
}

#body-root .appearance-none {
  appearance: none;
}

#body-root .columns-2 {
  columns: 2;
}

#body-root .break-inside-avoid {
  break-inside: avoid;
}

#body-root .auto-cols-max {
  grid-auto-columns: max-content;
}

#body-root .grid-flow-col {
  grid-auto-flow: column;
}

#body-root .auto-rows-\[30px\] {
  grid-auto-rows: 30px;
}

#body-root .grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

#body-root .grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

#body-root .grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

#body-root .grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

#body-root .grid-cols-\[repeat\(auto-fit\2c minmax\(24px\2c 1fr\)\)\] {
  grid-template-columns: repeat(auto-fit,minmax(24px,1fr));
}

#body-root .grid-rows-\[1fr\2c min-content\] {
  grid-template-rows: 1fr min-content;
}

#body-root .flex-row {
  flex-direction: row;
}

#body-root .flex-row-reverse {
  flex-direction: row-reverse;
}

#body-root .\!flex-col {
  flex-direction: column !important;
}

#body-root .flex-col {
  flex-direction: column;
}

#body-root .flex-col-reverse {
  flex-direction: column-reverse;
}

#body-root .flex-wrap {
  flex-wrap: wrap;
}

#body-root .\!flex-nowrap {
  flex-wrap: nowrap !important;
}

#body-root .flex-nowrap {
  flex-wrap: nowrap;
}

#body-root .place-content-center {
  place-content: center;
}

#body-root .place-content-start {
  place-content: start;
}

#body-root .place-items-end {
  place-items: end;
}

#body-root .content-center {
  align-content: center;
}

#body-root .content-end {
  align-content: flex-end;
}

#body-root .content-between {
  align-content: space-between;
}

#body-root .content-around {
  align-content: space-around;
}

#body-root .\!items-start {
  align-items: flex-start !important;
}

#body-root .items-start {
  align-items: flex-start;
}

#body-root .\!items-end {
  align-items: flex-end !important;
}

#body-root .items-end {
  align-items: flex-end;
}

#body-root .\!items-center {
  align-items: center !important;
}

#body-root .items-center {
  align-items: center;
}

#body-root .\!items-baseline {
  align-items: baseline !important;
}

#body-root .items-baseline {
  align-items: baseline;
}

#body-root .\!justify-start {
  justify-content: flex-start !important;
}

#body-root .justify-start {
  justify-content: flex-start;
}

#body-root .\!justify-end {
  justify-content: flex-end !important;
}

#body-root .justify-end {
  justify-content: flex-end;
}

#body-root .\!justify-center {
  justify-content: center !important;
}

#body-root .justify-center {
  justify-content: center;
}

#body-root .\!justify-between {
  justify-content: space-between !important;
}

#body-root .justify-between {
  justify-content: space-between;
}

#body-root .justify-around {
  justify-content: space-around;
}

#body-root .justify-evenly {
  justify-content: space-evenly;
}

#body-root .\!gap-0 {
  gap: 0px !important;
}

#body-root .\!gap-1 {
  gap: 4px !important;
}

#body-root .\!gap-\[3px\] {
  gap: 3px !important;
}

#body-root .\!gap-\[4px\] {
  gap: 4px !important;
}

#body-root .\!gap-\[6px\] {
  gap: 6px !important;
}

#body-root .gap-0 {
  gap: 0px;
}

#body-root .gap-0\.5 {
  gap: 2px;
}

#body-root .gap-1 {
  gap: 4px;
}

#body-root .gap-1\.5 {
  gap: 6px;
}

#body-root .gap-10 {
  gap: 40px;
}

#body-root .gap-14 {
  gap: 56px;
}

#body-root .gap-2 {
  gap: 8px;
}

#body-root .gap-2\.5 {
  gap: 10px;
}

#body-root .gap-3 {
  gap: 12px;
}

#body-root .gap-4 {
  gap: 16px;
}

#body-root .gap-5 {
  gap: 20px;
}

#body-root .gap-6 {
  gap: 24px;
}

#body-root .gap-7 {
  gap: 28px;
}

#body-root .gap-8 {
  gap: 32px;
}

#body-root .gap-\[10px\] {
  gap: 10px;
}

#body-root .gap-\[12px\] {
  gap: 12px;
}

#body-root .gap-\[14px\] {
  gap: 14px;
}

#body-root .gap-\[15px\] {
  gap: 15px;
}

#body-root .gap-\[16px\] {
  gap: 16px;
}

#body-root .gap-\[20px\] {
  gap: 20px;
}

#body-root .gap-\[24px\] {
  gap: 24px;
}

#body-root .gap-\[2px\] {
  gap: 2px;
}

#body-root .gap-\[32px\] {
  gap: 32px;
}

#body-root .gap-\[40px\] {
  gap: 40px;
}

#body-root .gap-\[4px\] {
  gap: 4px;
}

#body-root .gap-\[6px\] {
  gap: 6px;
}

#body-root .gap-\[8px\] {
  gap: 8px;
}

#body-root .\!gap-x-2 {
  column-gap: 8px !important;
}

#body-root .\!gap-y-1 {
  row-gap: 4px !important;
}

#body-root .gap-x-0\.5 {
  column-gap: 2px;
}

#body-root .gap-x-1\.5 {
  column-gap: 6px;
}

#body-root .gap-x-2 {
  column-gap: 8px;
}

#body-root .gap-x-4 {
  column-gap: 16px;
}

#body-root .gap-x-\[0\.25em\] {
  column-gap: 0.25em;
}

#body-root .gap-x-\[18px\] {
  column-gap: 18px;
}

#body-root .gap-x-\[24px\] {
  column-gap: 24px;
}

#body-root .gap-x-\[40px\] {
  column-gap: 40px;
}

#body-root .gap-y-0 {
  row-gap: 0px;
}

#body-root .gap-y-0\.5 {
  row-gap: 2px;
}

#body-root .gap-y-1 {
  row-gap: 4px;
}

#body-root .gap-y-3 {
  row-gap: 12px;
}

#body-root .gap-y-7 {
  row-gap: 28px;
}

#body-root .gap-y-\[16px\] {
  row-gap: 16px;
}

#body-root .gap-y-\[24px\] {
  row-gap: 24px;
}

#body-root .gap-y-\[26px\] {
  row-gap: 26px;
}

#body-root :is(.space-y-2 > :not([hidden]) ~ :not([hidden])) {
  --tw-space-y-reverse: 0;
  margin-top: calc(8px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(8px * var(--tw-space-y-reverse));
}

#body-root :is(.space-y-4 > :not([hidden]) ~ :not([hidden])) {
  --tw-space-y-reverse: 0;
  margin-top: calc(16px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(16px * var(--tw-space-y-reverse));
}

#body-root :is(.space-y-6 > :not([hidden]) ~ :not([hidden])) {
  --tw-space-y-reverse: 0;
  margin-top: calc(24px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(24px * var(--tw-space-y-reverse));
}

#body-root .place-self-start {
  place-self: start;
}

#body-root .place-self-end {
  place-self: end;
}

#body-root .place-self-center {
  place-self: center;
}

#body-root .\!self-start {
  align-self: flex-start !important;
}

#body-root .self-start {
  align-self: flex-start;
}

#body-root .\!self-end {
  align-self: flex-end !important;
}

#body-root .self-end {
  align-self: flex-end;
}

#body-root .\!self-center {
  align-self: center !important;
}

#body-root .self-center {
  align-self: center;
}

#body-root .\!self-stretch {
  align-self: stretch !important;
}

#body-root .self-stretch {
  align-self: stretch;
}

#body-root .\!overflow-auto {
  overflow: auto !important;
}

#body-root .overflow-auto {
  overflow: auto;
}

#body-root .\!overflow-hidden {
  overflow: hidden !important;
}

#body-root .overflow-hidden {
  overflow: hidden;
}

#body-root .overflow-visible {
  overflow: visible;
}

#body-root .overflow-scroll {
  overflow: scroll;
}

#body-root .\!overflow-y-auto {
  overflow-y: auto !important;
}

#body-root .overflow-y-auto {
  overflow-y: auto;
}

#body-root .\!overflow-x-hidden {
  overflow-x: hidden !important;
}

#body-root .overflow-x-hidden {
  overflow-x: hidden;
}

#body-root .\!overflow-y-hidden {
  overflow-y: hidden !important;
}

#body-root .overflow-y-hidden {
  overflow-y: hidden;
}

#body-root .\!overflow-x-scroll {
  overflow-x: scroll !important;
}

#body-root .overflow-y-scroll {
  overflow-y: scroll;
}

#body-root .truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#body-root .\!text-ellipsis {
  text-overflow: ellipsis !important;
}

#body-root .text-ellipsis {
  text-overflow: ellipsis;
}

#body-root .whitespace-normal {
  white-space: normal;
}

#body-root .whitespace-nowrap {
  white-space: nowrap;
}

#body-root .text-wrap {
  text-wrap: wrap;
}

#body-root .text-nowrap {
  text-wrap: nowrap;
}

#body-root .break-words {
  overflow-wrap: break-word;
}

#body-root .\!rounded-none {
  border-radius: 0px !important;
}

#body-root .rounded {
  border-radius: 4px;
}

#body-root .rounded-\[10px\] {
  border-radius: 10px;
}

#body-root .rounded-\[15px\] {
  border-radius: 15px;
}

#body-root .rounded-\[30px\] {
  border-radius: 30px;
}

#body-root .rounded-\[32px\] {
  border-radius: 32px;
}

#body-root .rounded-\[50px\] {
  border-radius: 50px;
}

#body-root .rounded-full {
  border-radius: 9999px;
}

#body-root .rounded-md {
  border-radius: 6px;
}

#body-root .rounded-none {
  border-radius: 0px;
}

#body-root .\!border {
  border-width: 1px !important;
}

#body-root .\!border-\[0\.5px\] {
  border-width: 0.5px !important;
}

#body-root .border {
  border-width: 1px;
}

#body-root .border-0 {
  border-width: 0px;
}

#body-root .border-4 {
  border-width: 4px;
}

#body-root .border-8 {
  border-width: 8px;
}

#body-root .border-\[0\.5px\] {
  border-width: 0.5px;
}

#body-root .border-\[0\] {
  border-width: 0;
}

#body-root .border-\[1px\] {
  border-width: 1px;
}

#body-root .border-\[3px\] {
  border-width: 3px;
}

#body-root .border-x-\[0\.5px\] {
  border-left-width: 0.5px;
  border-right-width: 0.5px;
}

#body-root .border-y-\[0\.5px\] {
  border-top-width: 0.5px;
  border-bottom-width: 0.5px;
}

#body-root .\!border-b-\[0\.5px\] {
  border-bottom-width: 0.5px !important;
}

#body-root .\!border-t-\[0\.5px\] {
  border-top-width: 0.5px !important;
}

#body-root .border-b {
  border-bottom-width: 1px;
}

#body-root .border-b-\[0\.5px\] {
  border-bottom-width: 0.5px;
}

#body-root .border-b-\[0\.75px\] {
  border-bottom-width: 0.75px;
}

#body-root .border-b-\[1\.5px\] {
  border-bottom-width: 1.5px;
}

#body-root .border-b-\[1px\] {
  border-bottom-width: 1px;
}

#body-root .border-l-\[0\.5px\] {
  border-left-width: 0.5px;
}

#body-root .border-l-\[0\.75px\] {
  border-left-width: 0.75px;
}

#body-root .border-r-0 {
  border-right-width: 0px;
}

#body-root .border-r-\[0\.5px\] {
  border-right-width: 0.5px;
}

#body-root .border-r-\[0\.75px\] {
  border-right-width: 0.75px;
}

#body-root .border-t {
  border-top-width: 1px;
}

#body-root .border-t-\[0\.5px\] {
  border-top-width: 0.5px;
}

#body-root .border-t-\[0\.75px\] {
  border-top-width: 0.75px;
}

#body-root .border-t-\[1\.5px\] {
  border-top-width: 1.5px;
}

#body-root .\!border-solid {
  border-style: solid !important;
}

#body-root .border-solid {
  border-style: solid;
}

#body-root .border-none {
  border-style: none;
}

#body-root .\!border-\[\#666\] {
  --tw-border-opacity: 1 !important;
  border-color: rgb(102 102 102 / var(--tw-border-opacity)) !important;
}

#body-root .\!border-\[\#999999\] {
  --tw-border-opacity: 1 !important;
  border-color: rgb(153 153 153 / var(--tw-border-opacity)) !important;
}

#body-root .\!border-\[\#E9E9E9\] {
  --tw-border-opacity: 1 !important;
  border-color: rgb(233 233 233 / var(--tw-border-opacity)) !important;
}

#body-root .\!border-black {
  border-color: rgba(0, 0, 0, 1) !important;
}

#body-root .\!border-gray-1 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(64 64 64 / var(--tw-border-opacity)) !important;
}

#body-root .\!border-gray-200 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(229 231 235 / var(--tw-border-opacity)) !important;
}

#body-root .border-\[\#0000001f\] {
  border-color: #0000001f;
}

#body-root .border-\[\#000\] {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
}

#body-root .border-\[\#201f1f\] {
  --tw-border-opacity: 1;
  border-color: rgb(32 31 31 / var(--tw-border-opacity));
}

#body-root .border-\[\#666666\] {
  --tw-border-opacity: 1;
  border-color: rgb(102 102 102 / var(--tw-border-opacity));
}

#body-root .border-\[\#898886\] {
  --tw-border-opacity: 1;
  border-color: rgb(137 136 134 / var(--tw-border-opacity));
}

#body-root .border-\[\#999\] {
  --tw-border-opacity: 1;
  border-color: rgb(153 153 153 / var(--tw-border-opacity));
}

#body-root .border-\[\#D4D2CF\] {
  --tw-border-opacity: 1;
  border-color: rgb(212 210 207 / var(--tw-border-opacity));
}

#body-root .border-\[\#ddd\] {
  --tw-border-opacity: 1;
  border-color: rgb(221 221 221 / var(--tw-border-opacity));
}

#body-root .border-\[rgba\(33\2c 33\2c 33\2c 0\.5\)\] {
  border-color: rgba(33,33,33,0.5);
}

#body-root .border-black {
  border-color: rgba(0, 0, 0, 1);
}

#body-root .border-black\/40 {
  border-color: rgba(0, 0, 0, 0.4);
}

#body-root .border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

#body-root .border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

#body-root .border-gray-500 {
  --tw-border-opacity: 1;
  border-color: rgb(212 210 207 / var(--tw-border-opacity));
}

#body-root .border-gray-600 {
  --tw-border-opacity: 1;
  border-color: rgb(102 102 102 / var(--tw-border-opacity));
}

#body-root .border-rh-dark {
  --tw-border-opacity: 1;
  border-color: rgb(137 136 134 / var(--tw-border-opacity));
}

#body-root .border-rh-darker {
  --tw-border-opacity: 1;
  border-color: rgb(32 31 31 / var(--tw-border-opacity));
}

#body-root .border-rh-darker\/20 {
  border-color: rgb(32 31 31 / 0.2);
}

#body-root .border-rh-grey {
  --tw-border-opacity: 1;
  border-color: rgb(233 233 233 / var(--tw-border-opacity));
}

#body-root .border-rh-light-grey {
  --tw-border-opacity: 1;
  border-color: rgb(204 204 204 / var(--tw-border-opacity));
}

#body-root .border-slate-200 {
  --tw-border-opacity: 1;
  border-color: rgb(226 232 240 / var(--tw-border-opacity));
}

#body-root .border-white {
  border-color: rgba(255, 255, 255, 1);
}

#body-root .border-b-\[\#e6e6e6\] {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(230 230 230 / var(--tw-border-opacity));
}

#body-root .border-l-\[\#E1E1E1\] {
  --tw-border-opacity: 1;
  border-left-color: rgb(225 225 225 / var(--tw-border-opacity));
}

#body-root .border-r-\[\#E1E1E1\] {
  --tw-border-opacity: 1;
  border-right-color: rgb(225 225 225 / var(--tw-border-opacity));
}

#body-root .border-opacity-50 {
  --tw-border-opacity: 0.5;
}

#body-root .\!bg-\[\#F1F0ED\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(241 240 237 / var(--tw-bg-opacity)) !important;
}

#body-root .\!bg-\[\#F9F7F4\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(249 247 244 / var(--tw-bg-opacity)) !important;
}

#body-root .\!bg-\[\#ffffff\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
}

#body-root .\!bg-\[rgba\(255\2c 255\2c 255\2c 0\.2\)\] {
  background-color: rgba(255,255,255,0.2) !important;
}

#body-root .\!bg-black {
  background-color: rgba(0, 0, 0, 1) !important;
}

#body-root .\!bg-gray-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(212 210 207 / var(--tw-bg-opacity)) !important;
}

#body-root .\!bg-rh-buff {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(249 247 244 / var(--tw-bg-opacity)) !important;
}

#body-root .\!bg-rh-dark {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(137 136 134 / var(--tw-bg-opacity)) !important;
}

#body-root .\!bg-transparent {
  background-color: rgba(0, 0, 0, 0) !important;
}

#body-root .\!bg-white {
  background-color: rgba(255, 255, 255, 1) !important;
}

#body-root .\!bg-white-400 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(248 248 248 / var(--tw-bg-opacity)) !important;
}

#body-root .\!bg-white-500 {
  background-color: #ffffff33 !important;
}

#body-root .\!bg-white\/30 {
  background-color: rgba(255, 255, 255, 0.3) !important;
}

#body-root .\!bg-white\/60 {
  background-color: rgba(255, 255, 255, 0.6) !important;
}

#body-root .bg-\[\#00000066\] {
  background-color: #00000066;
}

#body-root .bg-\[\#666\] {
  --tw-bg-opacity: 1;
  background-color: rgb(102 102 102 / var(--tw-bg-opacity));
}

#body-root .bg-\[\#D4D2CF\]\/60 {
  background-color: rgb(212 210 207 / 0.6);
}

#body-root .bg-\[\#D7D6D3\] {
  --tw-bg-opacity: 1;
  background-color: rgb(215 214 211 / var(--tw-bg-opacity));
}

#body-root .bg-\[\#DDDDDD\] {
  --tw-bg-opacity: 1;
  background-color: rgb(221 221 221 / var(--tw-bg-opacity));
}

#body-root .bg-\[\#DDD\] {
  --tw-bg-opacity: 1;
  background-color: rgb(221 221 221 / var(--tw-bg-opacity));
}

#body-root .bg-\[\#F1EFEC\] {
  --tw-bg-opacity: 1;
  background-color: rgb(241 239 236 / var(--tw-bg-opacity));
}

#body-root .bg-\[\#F5F5F5\] {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity));
}

#body-root .bg-\[\#F9F7F4\] {
  --tw-bg-opacity: 1;
  background-color: rgb(249 247 244 / var(--tw-bg-opacity));
}

#body-root .bg-\[\#FFF\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

#body-root .bg-\[\#d9d9d9\] {
  --tw-bg-opacity: 1;
  background-color: rgb(217 217 217 / var(--tw-bg-opacity));
}

#body-root .bg-\[\#f1f0Ed\] {
  --tw-bg-opacity: 1;
  background-color: rgb(241 240 237 / var(--tw-bg-opacity));
}

#body-root .bg-\[\#f1f0ed\] {
  --tw-bg-opacity: 1;
  background-color: rgb(241 240 237 / var(--tw-bg-opacity));
}

#body-root .bg-\[\#f9f7f4\] {
  --tw-bg-opacity: 1;
  background-color: rgb(249 247 244 / var(--tw-bg-opacity));
}

#body-root .bg-\[rgba\(0\2c 0\2c 0\2c 0\)\] {
  background-color: rgba(0,0,0,0);
}

#body-root .bg-\[rgba\(255\2c 255\2c 255\2c 0\.60\)\] {
  background-color: rgba(255,255,255,0.60);
}

#body-root .bg-black {
  background-color: rgba(0, 0, 0, 1);
}

#body-root .bg-black\/40 {
  background-color: rgba(0, 0, 0, 0.4);
}

#body-root .bg-black\/80 {
  background-color: rgba(0, 0, 0, 0.8);
}

#body-root .bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}

#body-root .bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(212 210 207 / var(--tw-bg-opacity));
}

#body-root .bg-gray-500\/40 {
  background-color: rgb(212 210 207 / 0.4);
}

#body-root .bg-rh-buff {
  --tw-bg-opacity: 1;
  background-color: rgb(249 247 244 / var(--tw-bg-opacity));
}

#body-root .bg-rh-grey-skeleton {
  --tw-bg-opacity: 1;
  background-color: rgb(241 239 236 / var(--tw-bg-opacity));
}

#body-root .bg-rh-light {
  --tw-bg-opacity: 1;
  background-color: rgb(241 240 237 / var(--tw-bg-opacity));
}

#body-root .bg-slate-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(248 250 252 / var(--tw-bg-opacity));
}

#body-root .bg-transparent {
  background-color: rgba(0, 0, 0, 0);
}

#body-root .bg-white {
  background-color: rgba(255, 255, 255, 1);
}

#body-root .bg-white-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(241 241 241 / var(--tw-bg-opacity));
}

#body-root .bg-white\/20 {
  background-color: rgba(255, 255, 255, 0.2);
}

#body-root .bg-white\/90 {
  background-color: rgba(255, 255, 255, 0.9);
}

#body-root .bg-opacity-0 {
  --tw-bg-opacity: 0;
}

#body-root .bg-opacity-30 {
  --tw-bg-opacity: 0.3;
}

#body-root .bg-opacity-50 {
  --tw-bg-opacity: 0.5;
}

#body-root .bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

#body-root .bg-swatch-overlay-gradient {
  background-image: linear-gradient(270deg, #130D03 0.57%, rgba(54, 47, 35, 0.60) 4.48%, rgba(194, 193, 191, 0.14) 46.96%, rgba(164, 164, 164, 0.19) 95.03%, rgba(0, 0, 0, 0.37) 100%);
}

#body-root .from-gray-300 {
  --tw-gradient-from: #d1d5db var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(209 213 219 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

#body-root .via-gray-200 {
  --tw-gradient-to: rgb(229 231 235 / 0)  var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb var(--tw-gradient-via-position), var(--tw-gradient-to);
}

#body-root .to-gray-300 {
  --tw-gradient-to: #d1d5db var(--tw-gradient-to-position);
}

#body-root .\!fill-none {
  fill: none !important;
}

#body-root .fill-black {
  fill: rgba(0, 0, 0, 1);
}

#body-root .fill-current {
  fill: currentColor;
}

#body-root .fill-none {
  fill: none;
}

#body-root .stroke-current {
  stroke: currentColor;
}

#body-root .stroke-\[0\.75px\] {
  stroke-width: 0.75px;
}

#body-root .\!object-contain {
  object-fit: contain !important;
}

#body-root .object-contain {
  object-fit: contain;
}

#body-root .object-cover {
  object-fit: cover;
}

#body-root .\!object-fill {
  object-fit: fill !important;
}

#body-root .object-fill {
  object-fit: fill;
}

#body-root .\!p-0 {
  padding: 0px !important;
}

#body-root .\!p-2 {
  padding: 8px !important;
}

#body-root .\!p-3 {
  padding: 12px !important;
}

#body-root .\!p-4 {
  padding: 16px !important;
}

#body-root .\!p-\[0px\] {
  padding: 0px !important;
}

#body-root .\!p-\[16px\] {
  padding: 16px !important;
}

#body-root .\!p-\[18px\] {
  padding: 18px !important;
}

#body-root .\!p-\[2px\] {
  padding: 2px !important;
}

#body-root .\!p-\[6px\] {
  padding: 6px !important;
}

#body-root .p-0 {
  padding: 0px;
}

#body-root .p-0\.5 {
  padding: 2px;
}

#body-root .p-10 {
  padding: 40px;
}

#body-root .p-12 {
  padding: 48px;
}

#body-root .p-2 {
  padding: 8px;
}

#body-root .p-2\.5 {
  padding: 10px;
}

#body-root .p-3 {
  padding: 12px;
}

#body-root .p-4 {
  padding: 16px;
}

#body-root .p-5 {
  padding: 20px;
}

#body-root .p-6 {
  padding: 24px;
}

#body-root .p-8 {
  padding: 32px;
}

#body-root .p-\[10px\] {
  padding: 10px;
}

#body-root .p-\[16px\] {
  padding: 16px;
}

#body-root .p-\[17px_22px\] {
  padding: 17px 22px;
}

#body-root .p-\[20px\] {
  padding: 20px;
}

#body-root .p-\[25px\] {
  padding: 25px;
}

#body-root .p-\[2px\] {
  padding: 2px;
}

#body-root .p-\[32px\] {
  padding: 32px;
}

#body-root .p-\[6px_2px_4px_0px\] {
  padding: 6px 2px 4px 0px;
}

#body-root .\!px-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

#body-root .\!px-1 {
  padding-left: 4px !important;
  padding-right: 4px !important;
}

#body-root .\!px-2 {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

#body-root .\!px-3\.5 {
  padding-left: 14px !important;
  padding-right: 14px !important;
}

#body-root .\!px-5 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

#body-root .\!px-8 {
  padding-left: 32px !important;
  padding-right: 32px !important;
}

#body-root .\!px-\[0px\] {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

#body-root .\!px-\[16px\] {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

#body-root .\!px-\[18px\] {
  padding-left: 18px !important;
  padding-right: 18px !important;
}

#body-root .\!px-\[1px\] {
  padding-left: 1px !important;
  padding-right: 1px !important;
}

#body-root .\!px-\[30px\] {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

#body-root .\!px-\[32px\] {
  padding-left: 32px !important;
  padding-right: 32px !important;
}

#body-root .\!px-\[60px\] {
  padding-left: 60px !important;
  padding-right: 60px !important;
}

#body-root .\!px-\[90px\] {
  padding-left: 90px !important;
  padding-right: 90px !important;
}

#body-root .\!py-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

#body-root .\!py-1 {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

#body-root .\!py-10 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

#body-root .\!py-4 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

#body-root .\!py-\[16\.5px\] {
  padding-top: 16.5px !important;
  padding-bottom: 16.5px !important;
}

#body-root .\!py-\[16px\] {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

#body-root .\!py-\[17\.5px\] {
  padding-top: 17.5px !important;
  padding-bottom: 17.5px !important;
}

#body-root .\!py-\[5px\] {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

#body-root .px-0 {
  padding-left: 0px;
  padding-right: 0px;
}

#body-root .px-1 {
  padding-left: 4px;
  padding-right: 4px;
}

#body-root .px-1\.5 {
  padding-left: 6px;
  padding-right: 6px;
}

#body-root .px-10 {
  padding-left: 40px;
  padding-right: 40px;
}

#body-root .px-12 {
  padding-left: 48px;
  padding-right: 48px;
}

#body-root .px-2 {
  padding-left: 8px;
  padding-right: 8px;
}

#body-root .px-3 {
  padding-left: 12px;
  padding-right: 12px;
}

#body-root .px-4 {
  padding-left: 16px;
  padding-right: 16px;
}

#body-root .px-6 {
  padding-left: 24px;
  padding-right: 24px;
}

#body-root .px-8 {
  padding-left: 32px;
  padding-right: 32px;
}

#body-root .px-\[15px\] {
  padding-left: 15px;
  padding-right: 15px;
}

#body-root .px-\[187\.36px\] {
  padding-left: 187.36px;
  padding-right: 187.36px;
}

#body-root .px-\[18px\] {
  padding-left: 18px;
  padding-right: 18px;
}

#body-root .px-\[1px\] {
  padding-left: 1px;
  padding-right: 1px;
}

#body-root .px-\[20px\] {
  padding-left: 20px;
  padding-right: 20px;
}

#body-root .px-\[30px\] {
  padding-left: 30px;
  padding-right: 30px;
}

#body-root .px-\[32px\] {
  padding-left: 32px;
  padding-right: 32px;
}

#body-root .px-\[40px\] {
  padding-left: 40px;
  padding-right: 40px;
}

#body-root .px-\[7\%\] {
  padding-left: 7%;
  padding-right: 7%;
}

#body-root .px-\[82px\] {
  padding-left: 82px;
  padding-right: 82px;
}

#body-root .py-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}

#body-root .py-0\.5 {
  padding-top: 2px;
  padding-bottom: 2px;
}

#body-root .py-1 {
  padding-top: 4px;
  padding-bottom: 4px;
}

#body-root .py-1\.5 {
  padding-top: 6px;
  padding-bottom: 6px;
}

#body-root .py-10 {
  padding-top: 40px;
  padding-bottom: 40px;
}

#body-root .py-12 {
  padding-top: 48px;
  padding-bottom: 48px;
}

#body-root .py-2 {
  padding-top: 8px;
  padding-bottom: 8px;
}

#body-root .py-2\.5 {
  padding-top: 10px;
  padding-bottom: 10px;
}

#body-root .py-3 {
  padding-top: 12px;
  padding-bottom: 12px;
}

#body-root .py-4 {
  padding-top: 16px;
  padding-bottom: 16px;
}

#body-root .py-6 {
  padding-top: 24px;
  padding-bottom: 24px;
}

#body-root .py-9 {
  padding-top: 36px;
  padding-bottom: 36px;
}

#body-root .py-\[10px\] {
  padding-top: 10px;
  padding-bottom: 10px;
}

#body-root .py-\[14px\] {
  padding-top: 14px;
  padding-bottom: 14px;
}

#body-root .py-\[15px\] {
  padding-top: 15px;
  padding-bottom: 15px;
}

#body-root .py-\[19px\] {
  padding-top: 19px;
  padding-bottom: 19px;
}

#body-root .py-\[20px\] {
  padding-top: 20px;
  padding-bottom: 20px;
}

#body-root .py-\[22\.4px\] {
  padding-top: 22.4px;
  padding-bottom: 22.4px;
}

#body-root .py-\[24px\] {
  padding-top: 24px;
  padding-bottom: 24px;
}

#body-root .py-\[27px\] {
  padding-top: 27px;
  padding-bottom: 27px;
}

#body-root .py-\[40px\] {
  padding-top: 40px;
  padding-bottom: 40px;
}

#body-root .py-\[55px\] {
  padding-top: 55px;
  padding-bottom: 55px;
}

#body-root .py-\[5px\] {
  padding-top: 5px;
  padding-bottom: 5px;
}

#body-root .py-\[8px\] {
  padding-top: 8px;
  padding-bottom: 8px;
}

#body-root .\!pb-0 {
  padding-bottom: 0px !important;
}

#body-root .\!pb-2 {
  padding-bottom: 8px !important;
}

#body-root .\!pb-2\.5 {
  padding-bottom: 10px !important;
}

#body-root .\!pb-3 {
  padding-bottom: 12px !important;
}

#body-root .\!pb-5 {
  padding-bottom: 20px !important;
}

#body-root .\!pb-8 {
  padding-bottom: 32px !important;
}

#body-root .\!pb-\[0\.3333333333333333rem\] {
  padding-bottom: 0.3333333333333333rem !important;
}

#body-root .\!pb-\[15px\] {
  padding-bottom: 15px !important;
}

#body-root .\!pb-\[17px\] {
  padding-bottom: 17px !important;
}

#body-root .\!pb-\[24px\] {
  padding-bottom: 24px !important;
}

#body-root .\!pb-\[3px\] {
  padding-bottom: 3px !important;
}

#body-root .\!pb-\[48px\] {
  padding-bottom: 48px !important;
}

#body-root .\!pb-\[4px\] {
  padding-bottom: 4px !important;
}

#body-root .\!pl-0 {
  padding-left: 0px !important;
}

#body-root .\!pl-1 {
  padding-left: 4px !important;
}

#body-root .\!pl-10 {
  padding-left: 40px !important;
}

#body-root .\!pl-2 {
  padding-left: 8px !important;
}

#body-root .\!pl-4 {
  padding-left: 16px !important;
}

#body-root .\!pl-5 {
  padding-left: 20px !important;
}

#body-root .\!pl-8 {
  padding-left: 32px !important;
}

#body-root .\!pl-\[1px\] {
  padding-left: 1px !important;
}

#body-root .\!pl-\[3\.2px\] {
  padding-left: 3.2px !important;
}

#body-root .\!pl-\[5px\] {
  padding-left: 5px !important;
}

#body-root .\!pr-0 {
  padding-right: 0px !important;
}

#body-root .\!pr-2 {
  padding-right: 8px !important;
}

#body-root .\!pr-5 {
  padding-right: 20px !important;
}

#body-root .\!pr-6 {
  padding-right: 24px !important;
}

#body-root .\!pr-\[2px\] {
  padding-right: 2px !important;
}

#body-root .\!pr-\[3px\] {
  padding-right: 3px !important;
}

#body-root .\!pr-\[5px\] {
  padding-right: 5px !important;
}

#body-root .\!pt-0 {
  padding-top: 0px !important;
}

#body-root .\!pt-0\.5 {
  padding-top: 2px !important;
}

#body-root .\!pt-1 {
  padding-top: 4px !important;
}

#body-root .\!pt-5 {
  padding-top: 20px !important;
}

#body-root .\!pt-6 {
  padding-top: 24px !important;
}

#body-root .\!pt-8 {
  padding-top: 32px !important;
}

#body-root .\!pt-\[0\.3333333333333333rem\] {
  padding-top: 0.3333333333333333rem !important;
}

#body-root .\!pt-\[0px\] {
  padding-top: 0px !important;
}

#body-root .\!pt-\[12\.8px\] {
  padding-top: 12.8px !important;
}

#body-root .\!pt-\[12px\] {
  padding-top: 12px !important;
}

#body-root .\!pt-\[15px\] {
  padding-top: 15px !important;
}

#body-root .\!pt-\[16px\] {
  padding-top: 16px !important;
}

#body-root .\!pt-\[18px\] {
  padding-top: 18px !important;
}

#body-root .\!pt-\[20px\] {
  padding-top: 20px !important;
}

#body-root .\!pt-\[24px\] {
  padding-top: 24px !important;
}

#body-root .\!pt-\[3px\] {
  padding-top: 3px !important;
}

#body-root .\!pt-\[40px\] {
  padding-top: 40px !important;
}

#body-root .\!pt-\[45px\] {
  padding-top: 45px !important;
}

#body-root .\!pt-\[6px\] {
  padding-top: 6px !important;
}

#body-root .\!pt-\[8px\] {
  padding-top: 8px !important;
}

#body-root .pb-0 {
  padding-bottom: 0px;
}

#body-root .pb-1 {
  padding-bottom: 4px;
}

#body-root .pb-1\.5 {
  padding-bottom: 6px;
}

#body-root .pb-10 {
  padding-bottom: 40px;
}

#body-root .pb-12 {
  padding-bottom: 48px;
}

#body-root .pb-2 {
  padding-bottom: 8px;
}

#body-root .pb-2\.5 {
  padding-bottom: 10px;
}

#body-root .pb-4 {
  padding-bottom: 16px;
}

#body-root .pb-48 {
  padding-bottom: 192px;
}

#body-root .pb-6 {
  padding-bottom: 24px;
}

#body-root .pb-7 {
  padding-bottom: 28px;
}

#body-root .pb-8 {
  padding-bottom: 32px;
}

#body-root .pb-\[14\.4px\] {
  padding-bottom: 14.4px;
}

#body-root .pb-\[14px\] {
  padding-bottom: 14px;
}

#body-root .pb-\[16px\] {
  padding-bottom: 16px;
}

#body-root .pb-\[18px\] {
  padding-bottom: 18px;
}

#body-root .pb-\[1px\] {
  padding-bottom: 1px;
}

#body-root .pb-\[20px\] {
  padding-bottom: 20px;
}

#body-root .pb-\[24px\] {
  padding-bottom: 24px;
}

#body-root .pb-\[26px\] {
  padding-bottom: 26px;
}

#body-root .pb-\[27px\] {
  padding-bottom: 27px;
}

#body-root .pb-\[2px\] {
  padding-bottom: 2px;
}

#body-root .pb-\[32px\] {
  padding-bottom: 32px;
}

#body-root .pb-\[40px\] {
  padding-bottom: 40px;
}

#body-root .pb-\[8px\] {
  padding-bottom: 8px;
}

#body-root .pl-0 {
  padding-left: 0px;
}

#body-root .pl-1 {
  padding-left: 4px;
}

#body-root .pl-2 {
  padding-left: 8px;
}

#body-root .pl-2\.5 {
  padding-left: 10px;
}

#body-root .pl-3 {
  padding-left: 12px;
}

#body-root .pl-4 {
  padding-left: 16px;
}

#body-root .pl-5 {
  padding-left: 20px;
}

#body-root .pl-\[16px\] {
  padding-left: 16px;
}

#body-root .pl-\[1px\] {
  padding-left: 1px;
}

#body-root .pl-\[20px\] {
  padding-left: 20px;
}

#body-root .pl-\[30px\] {
  padding-left: 30px;
}

#body-root .pl-\[31px\] {
  padding-left: 31px;
}

#body-root .pl-\[32px\] {
  padding-left: 32px;
}

#body-root .pl-\[40px\] {
  padding-left: 40px;
}

#body-root .pr-0 {
  padding-right: 0px;
}

#body-root .pr-1 {
  padding-right: 4px;
}

#body-root .pr-2 {
  padding-right: 8px;
}

#body-root .pr-2\.5 {
  padding-right: 10px;
}

#body-root .pr-20 {
  padding-right: 80px;
}

#body-root .pr-4 {
  padding-right: 16px;
}

#body-root .pr-5 {
  padding-right: 20px;
}

#body-root .pr-6 {
  padding-right: 24px;
}

#body-root .pr-\[0px\] {
  padding-right: 0px;
}

#body-root .pr-\[12px\] {
  padding-right: 12px;
}

#body-root .pr-\[30px\] {
  padding-right: 30px;
}

#body-root .pr-\[32px\] {
  padding-right: 32px;
}

#body-root .pr-\[40px\] {
  padding-right: 40px;
}

#body-root .pr-\[80px\] {
  padding-right: 80px;
}

#body-root .ps-2 {
  padding-inline-start: 8px;
}

#body-root .pt-0 {
  padding-top: 0px;
}

#body-root .pt-1 {
  padding-top: 4px;
}

#body-root .pt-1\.5 {
  padding-top: 6px;
}

#body-root .pt-10 {
  padding-top: 40px;
}

#body-root .pt-16 {
  padding-top: 64px;
}

#body-root .pt-2 {
  padding-top: 8px;
}

#body-root .pt-2\.5 {
  padding-top: 10px;
}

#body-root .pt-3 {
  padding-top: 12px;
}

#body-root .pt-4 {
  padding-top: 16px;
}

#body-root .pt-5 {
  padding-top: 20px;
}

#body-root .pt-6 {
  padding-top: 24px;
}

#body-root .pt-8 {
  padding-top: 32px;
}

#body-root .pt-9 {
  padding-top: 36px;
}

#body-root .pt-\[0px\] {
  padding-top: 0px;
}

#body-root .pt-\[100\%\] {
  padding-top: 100%;
}

#body-root .pt-\[15px\] {
  padding-top: 15px;
}

#body-root .pt-\[17px\] {
  padding-top: 17px;
}

#body-root .pt-\[20px\] {
  padding-top: 20px;
}

#body-root .pt-\[22px\] {
  padding-top: 22px;
}

#body-root .pt-\[23px\] {
  padding-top: 23px;
}

#body-root .pt-\[24px\] {
  padding-top: 24px;
}

#body-root .pt-\[29px\] {
  padding-top: 29px;
}

#body-root .pt-\[30px\] {
  padding-top: 30px;
}

#body-root .pt-\[3px\] {
  padding-top: 3px;
}

#body-root .pt-\[40px\] {
  padding-top: 40px;
}

#body-root .pt-\[5px\] {
  padding-top: 5px;
}

#body-root .pt-\[80px\] {
  padding-top: 80px;
}

#body-root .pt-\[8px\] {
  padding-top: 8px;
}

#body-root .pt-\[96px\] {
  padding-top: 96px;
}

#body-root .\!text-left {
  text-align: left !important;
}

#body-root .text-left {
  text-align: left;
}

#body-root .\!text-center {
  text-align: center !important;
}

#body-root .text-center {
  text-align: center;
}

#body-root .text-right {
  text-align: right;
}

#body-root .text-justify {
  text-align: justify;
}

#body-root .text-start {
  text-align: start;
}

#body-root .text-end {
  text-align: end;
}

#body-root .\!align-baseline {
  vertical-align: baseline !important;
}

#body-root .align-baseline {
  vertical-align: baseline;
}

#body-root .align-top {
  vertical-align: top;
}

#body-root .\!align-middle {
  vertical-align: middle !important;
}

#body-root .align-middle {
  vertical-align: middle;
}

#body-root .align-super {
  vertical-align: super;
}

#body-root .\!font-\[CaslonRH-Thin\] {
  font-family: CaslonRH-Thin !important;
}

#body-root .\!font-\[RHC-Hairline\] {
  font-family: RHC-Hairline !important;
}

#body-root .\!font-\[RHSans-ExtraLight\] {
  font-family: RHSans-ExtraLight !important;
}

#body-root .\!font-primary-light {
  font-family: RHSans-Light, Helvetica, Arial !important;
}

#body-root .\!font-primary-rhlight {
  font-family: RHSans-Light !important;
}

#body-root .\!font-primary-rhroman {
  font-family: RHSans-Roman !important;
}

#body-root .\!font-primary-rhthin {
  font-family: RHSans-Thin !important;
}

#body-root .\!font-primary-ultra-thin {
  font-family: RHSans-UltraThin, Helvetica, Arial !important;
}

#body-root .\!font-rhsans {
  font-family: RHSans !important;
}

#body-root .font-\[RHSans-Hairline\] {
  font-family: RHSans-Hairline;
}

#body-root .font-\[inherit\] {
  font-family: inherit;
}

#body-root .font-primary-baron {
  font-family: Baron Sans;
}

#body-root .font-primary-light {
  font-family: RHSans-Light, Helvetica, Arial;
}

#body-root .font-primary-medium {
  font-family: RHSans-Medium, Helvetica, Arial;
}

#body-root .font-primary-rh-thin {
  font-family: RHSans-UltraThin;
}

#body-root .font-primary-rhc-roman {
  font-family: RHC-Roman, Helvetica, Arial;
}

#body-root .font-primary-rhhairline {
  font-family: RHSans-Hairline;
}

#body-root .font-primary-rhlight {
  font-family: RHSans-Light;
}

#body-root .font-primary-rhroman {
  font-family: RHSans-Roman;
}

#body-root .font-primary-rhsans {
  font-family: RHSans, Helvetica, Arial;
}

#body-root .font-primary-rhthin {
  font-family: RHSans-Thin;
}

#body-root .font-primary-thin {
  font-family: RHSans-Thin, Helvetica, Arial;
}

#body-root .font-primary-ultra-thin {
  font-family: RHSans-UltraThin, Helvetica, Arial;
}

#body-root .font-rhsans {
  font-family: RHSans;
}

#body-root .font-ultraThin {
  font-family: RHSans-UltraThin, sans-serif;
}

#body-root .\!text-11px {
  font-size: 11px !important;
}

#body-root .\!text-\[1\.25rem\] {
  font-size: 1.25rem !important;
}

#body-root .\!text-\[10px\] {
  font-size: 10px !important;
}

#body-root .\!text-\[11px\] {
  font-size: 11px !important;
}

#body-root .\!text-\[12px\] {
  font-size: 12px !important;
}

#body-root .\!text-\[13px\] {
  font-size: 13px !important;
}

#body-root .\!text-\[14px\] {
  font-size: 14px !important;
}

#body-root .\!text-\[15px\] {
  font-size: 15px !important;
}

#body-root .\!text-\[16px\] {
  font-size: 16px !important;
}

#body-root .\!text-\[18px\] {
  font-size: 18px !important;
}

#body-root .\!text-\[1rem\] {
  font-size: 1rem !important;
}

#body-root .\!text-\[2\.6666666666666665rem\] {
  font-size: 2.6666666666666665rem !important;
}

#body-root .\!text-\[20px\] {
  font-size: 20px !important;
}

#body-root .\!text-\[24px\] {
  font-size: 24px !important;
}

#body-root .\!text-\[28px\] {
  font-size: 28px !important;
}

#body-root .\!text-\[2rem\] {
  font-size: 2rem !important;
}

#body-root .\!text-\[30px\] {
  font-size: 30px !important;
}

#body-root .\!text-\[32px\] {
  font-size: 32px !important;
}

#body-root .\!text-\[40px\] {
  font-size: 40px !important;
}

#body-root .\!text-\[60px\] {
  font-size: 60px !important;
}

#body-root .\!text-\[68px\] {
  font-size: 68px !important;
}

#body-root .\!text-\[7px\] {
  font-size: 7px !important;
}

#body-root .\!text-\[86\%\] {
  font-size: 86% !important;
}

#body-root .\!text-\[8px\] {
  font-size: 8px !important;
}

#body-root .\!text-base {
  font-size: 16px !important;
}

#body-root .\!text-lg {
  font-size: 18px !important;
}

#body-root .\!text-sm {
  font-size: 12.8px !important;
}

#body-root .\!text-xs {
  font-size: 12px !important;
}

#body-root .text-11px {
  font-size: 11px;
}

#body-root .text-2xl {
  font-size: 25.008px;
}

#body-root .text-4xl {
  font-size: 39.056px;
}

#body-root .text-\[0\.83333rem\] {
  font-size: 0.83333rem;
}

#body-root .text-\[0\.91667rem\] {
  font-size: 0.91667rem;
}

#body-root .text-\[0\.9167rem\] {
  font-size: 0.9167rem;
}

#body-root .text-\[0\] {
  font-size: 0;
}

#body-root .text-\[1\.16667rem\] {
  font-size: 1.16667rem;
}

#body-root .text-\[1\.1667rem\] {
  font-size: 1.1667rem;
}

#body-root .text-\[1\.66667rem\] {
  font-size: 1.66667rem;
}

#body-root .text-\[1\.6667rem\] {
  font-size: 1.6667rem;
}

#body-root .text-\[10px\] {
  font-size: 10px;
}

#body-root .text-\[11px\] {
  font-size: 11px;
}

#body-root .text-\[12px\] {
  font-size: 12px;
}

#body-root .text-\[13px\] {
  font-size: 13px;
}

#body-root .text-\[14px\] {
  font-size: 14px;
}

#body-root .text-\[15px\] {
  font-size: 15px;
}

#body-root .text-\[16px\] {
  font-size: 16px;
}

#body-root .text-\[2\.083rem\] {
  font-size: 2.083rem;
}

#body-root .text-\[20px\] {
  font-size: 20px;
}

#body-root .text-\[22px\] {
  font-size: 22px;
}

#body-root .text-\[24px\] {
  font-size: 24px;
}

#body-root .text-\[25px\] {
  font-size: 25px;
}

#body-root .text-\[27px\] {
  font-size: 27px;
}

#body-root .text-\[28px\] {
  font-size: 28px;
}

#body-root .text-\[30px\] {
  font-size: 30px;
}

#body-root .text-\[32px\] {
  font-size: 32px;
}

#body-root .text-\[36px\] {
  font-size: 36px;
}

#body-root .text-\[40px\] {
  font-size: 40px;
}

#body-root .text-\[60px\] {
  font-size: 60px;
}

#body-root .text-\[7px\] {
  font-size: 7px;
}

#body-root .text-\[8px\] {
  font-size: 8px;
}

#body-root .text-base {
  font-size: 16px;
}

#body-root .text-lg {
  font-size: 18px;
}

#body-root .text-sm {
  font-size: 12.8px;
}

#body-root .text-xl {
  font-size: 20px;
}

#body-root .text-xs {
  font-size: 12px;
}

#body-root .text-xxs {
  font-size: 8px;
}

#body-root .\!font-\[100\] {
  font-weight: 100 !important;
}

#body-root .\!font-\[200\] {
  font-weight: 200 !important;
}

#body-root .\!font-\[300\] {
  font-weight: 300 !important;
}

#body-root .\!font-\[400\] {
  font-weight: 400 !important;
}

#body-root .\!font-\[50\] {
  font-weight: 50 !important;
}

#body-root .\!font-\[600\] {
  font-weight: 600 !important;
}

#body-root .\!font-\[75\] {
  font-weight: 75 !important;
}

#body-root .\!font-extralight {
  font-weight: 200 !important;
}

#body-root .\!font-light {
  font-weight: 300 !important;
}

#body-root .\!font-medium {
  font-weight: 500 !important;
}

#body-root .\!font-normal {
  font-weight: 400 !important;
}

#body-root .\!font-thin {
  font-weight: 100 !important;
}

#body-root .font-\[100\] {
  font-weight: 100;
}

#body-root .font-\[300\] {
  font-weight: 300;
}

#body-root .font-\[400\] {
  font-weight: 400;
}

#body-root .font-\[500\] {
  font-weight: 500;
}

#body-root .font-\[50\] {
  font-weight: 50;
}

#body-root .font-\[75\] {
  font-weight: 75;
}

#body-root .font-bold {
  font-weight: 700;
}

#body-root .font-extrabold {
  font-weight: 800;
}

#body-root .font-extralight {
  font-weight: 200;
}

#body-root .font-light {
  font-weight: 300;
}

#body-root .font-medium {
  font-weight: 500;
}

#body-root .font-normal {
  font-weight: 400;
}

#body-root .font-semibold {
  font-weight: 600;
}

#body-root .font-thin {
  font-weight: 100;
}

#body-root .\!uppercase {
  text-transform: uppercase !important;
}

#body-root .uppercase {
  text-transform: uppercase;
}

#body-root .lowercase {
  text-transform: lowercase;
}

#body-root .\!capitalize {
  text-transform: capitalize !important;
}

#body-root .capitalize {
  text-transform: capitalize;
}

#body-root .\!normal-case {
  text-transform: none !important;
}

#body-root .normal-case {
  text-transform: none;
}

#body-root .italic {
  font-style: italic;
}

#body-root .\!not-italic {
  font-style: normal !important;
}

#body-root .not-italic {
  font-style: normal;
}

#body-root .ordinal {
  --tw-ordinal: ordinal;
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
}

#body-root .\!leading-3 {
  line-height: 12px !important;
}

#body-root .\!leading-\[1\.33rem\] {
  line-height: 1.33rem !important;
}

#body-root .\!leading-\[1\.45\] {
  line-height: 1.45 !important;
}

#body-root .\!leading-\[110\%\] {
  line-height: 110% !important;
}

#body-root .\!leading-\[120\%\] {
  line-height: 120% !important;
}

#body-root .\!leading-\[13\.2px\] {
  line-height: 13.2px !important;
}

#body-root .\!leading-\[135\%\] {
  line-height: 135% !important;
}

#body-root .\!leading-\[13px\] {
  line-height: 13px !important;
}

#body-root .\!leading-\[15\.6px\] {
  line-height: 15.6px !important;
}

#body-root .\!leading-\[150\%\] {
  line-height: 150% !important;
}

#body-root .\!leading-\[16\.5px\] {
  line-height: 16.5px !important;
}

#body-root .\!leading-\[16px\] {
  line-height: 16px !important;
}

#body-root .\!leading-\[18px\] {
  line-height: 18px !important;
}

#body-root .\!leading-\[19\.5px\] {
  line-height: 19.5px !important;
}

#body-root .\!leading-\[19px\] {
  line-height: 19px !important;
}

#body-root .\!leading-\[2\.6666666666666665rem\] {
  line-height: 2.6666666666666665rem !important;
}

#body-root .\!leading-\[200\%\] {
  line-height: 200% !important;
}

#body-root .\!leading-\[85\%\] {
  line-height: 85% !important;
}

#body-root .\!leading-\[90\%\] {
  line-height: 90% !important;
}

#body-root .\!leading-none {
  line-height: 1 !important;
}

#body-root .leading-3 {
  line-height: 12px;
}

#body-root .leading-4 {
  line-height: 16px;
}

#body-root .leading-6 {
  line-height: 24px;
}

#body-root .leading-8 {
  line-height: 32px;
}

#body-root .leading-9 {
  line-height: 36px;
}

#body-root .leading-\[0\.9\] {
  line-height: 0.9;
}

#body-root .leading-\[0\] {
  line-height: 0;
}

#body-root .leading-\[1\.08333rem\] {
  line-height: 1.08333rem;
}

#body-root .leading-\[1\.0833rem\] {
  line-height: 1.0833rem;
}

#body-root .leading-\[1\.15\] {
  line-height: 1.15;
}

#body-root .leading-\[1\.1\] {
  line-height: 1.1;
}

#body-root .leading-\[1\.2\] {
  line-height: 1.2;
}

#body-root .leading-\[1\.6667rem\] {
  line-height: 1.6667rem;
}

#body-root .leading-\[1\.66\] {
  line-height: 1.66;
}

#body-root .leading-\[1\.91667rem\] {
  line-height: 1.91667rem;
}

#body-root .leading-\[1\.9167rem\] {
  line-height: 1.9167rem;
}

#body-root .leading-\[100\%\] {
  line-height: 100%;
}

#body-root .leading-\[110\%\] {
  line-height: 110%;
}

#body-root .leading-\[115\%\] {
  line-height: 115%;
}

#body-root .leading-\[11px\] {
  line-height: 11px;
}

#body-root .leading-\[12\.1px\] {
  line-height: 12.1px;
}

#body-root .leading-\[120\%\] {
  line-height: 120%;
}

#body-root .leading-\[125\%\] {
  line-height: 125%;
}

#body-root .leading-\[13\.20px\] {
  line-height: 13.20px;
}

#body-root .leading-\[13\.2px\] {
  line-height: 13.2px;
}

#body-root .leading-\[13\.75px\] {
  line-height: 13.75px;
}

#body-root .leading-\[13px\] {
  line-height: 13px;
}

#body-root .leading-\[14\.4px\] {
  line-height: 14.4px;
}

#body-root .leading-\[15\.6px\] {
  line-height: 15.6px;
}

#body-root .leading-\[150\%\] {
  line-height: 150%;
}

#body-root .leading-\[16\.25px\] {
  line-height: 16.25px;
}

#body-root .leading-\[16\.5px\] {
  line-height: 16.5px;
}

#body-root .leading-\[17\.25px\] {
  line-height: 17.25px;
}

#body-root .leading-\[18px\] {
  line-height: 18px;
}

#body-root .leading-\[19\.5px\] {
  line-height: 19.5px;
}

#body-root .leading-\[20px\] {
  line-height: 20px;
}

#body-root .leading-\[23px\] {
  line-height: 23px;
}

#body-root .leading-\[24px\] {
  line-height: 24px;
}

#body-root .leading-\[27px\] {
  line-height: 27px;
}

#body-root .leading-\[28\.6px\] {
  line-height: 28.6px;
}

#body-root .leading-\[2rem\] {
  line-height: 2rem;
}

#body-root .leading-\[30px\] {
  line-height: 30px;
}

#body-root .leading-\[32px\] {
  line-height: 32px;
}

#body-root .leading-\[90\%\] {
  line-height: 90%;
}

#body-root .leading-loose {
  line-height: 2;
}

#body-root .leading-none {
  line-height: 1;
}

#body-root .leading-normal {
  line-height: 1.5;
}

#body-root .leading-tight {
  line-height: 1.25;
}

#body-root .\!tracking-\[-0\.02em\] {
  letter-spacing: -0.02em !important;
}

#body-root .\!tracking-\[\.44px\] {
  letter-spacing: .44px !important;
}

#body-root .\!tracking-\[0\.015em\] {
  letter-spacing: 0.015em !important;
}

#body-root .\!tracking-\[0\.04em\] {
  letter-spacing: 0.04em !important;
}

#body-root .\!tracking-\[0\.11px\] {
  letter-spacing: 0.11px !important;
}

#body-root .\!tracking-\[0\.12px\] {
  letter-spacing: 0.12px !important;
}

#body-root .\!tracking-\[0\.15px\] {
  letter-spacing: 0.15px !important;
}

#body-root .\!tracking-\[0\.165px\] {
  letter-spacing: 0.165px !important;
}

#body-root .\!tracking-\[0\.18px\] {
  letter-spacing: 0.18px !important;
}

#body-root .\!tracking-\[0\.195px\] {
  letter-spacing: 0.195px !important;
}

#body-root .\!tracking-\[0\.1px\] {
  letter-spacing: 0.1px !important;
}

#body-root .\!tracking-\[0\.21px\] {
  letter-spacing: 0.21px !important;
}

#body-root .\!tracking-\[0\.2px\] {
  letter-spacing: 0.2px !important;
}

#body-root .\!tracking-\[0\.3px\] {
  letter-spacing: 0.3px !important;
}

#body-root .\!tracking-\[0\.44px\] {
  letter-spacing: 0.44px !important;
}

#body-root .\!tracking-\[0\.52px\] {
  letter-spacing: 0.52px !important;
}

#body-root .\!tracking-normal {
  letter-spacing: 0em !important;
}

#body-root .-tracking-\[0\.02em\] {
  letter-spacing: -0.02em;
}

#body-root .-tracking-\[0\.03em\] {
  letter-spacing: -0.03em;
}

#body-root .tracking-\[-0\.02em\] {
  letter-spacing: -0.02em;
}

#body-root .tracking-\[-0\.48px\] {
  letter-spacing: -0.48px;
}

#body-root .tracking-\[-0\.4px\] {
  letter-spacing: -0.4px;
}

#body-root .tracking-\[-0\.54px\] {
  letter-spacing: -0.54px;
}

#body-root .tracking-\[-0\.64px\] {
  letter-spacing: -0.64px;
}

#body-root .tracking-\[-0\.8px\] {
  letter-spacing: -0.8px;
}

#body-root .tracking-\[-1\.2px\] {
  letter-spacing: -1.2px;
}

#body-root .tracking-\[\.5px\] {
  letter-spacing: .5px;
}

#body-root .tracking-\[0\.015em\] {
  letter-spacing: 0.015em;
}

#body-root .tracking-\[0\.015px\] {
  letter-spacing: 0.015px;
}

#body-root .tracking-\[0\.04em\] {
  letter-spacing: 0.04em;
}

#body-root .tracking-\[0\.05em\] {
  letter-spacing: 0.05em;
}

#body-root .tracking-\[0\.165px\] {
  letter-spacing: 0.165px;
}

#body-root .tracking-\[0\.18px\] {
  letter-spacing: 0.18px;
}

#body-root .tracking-\[0\.195px\] {
  letter-spacing: 0.195px;
}

#body-root .tracking-\[0\.26px\] {
  letter-spacing: 0.26px;
}

#body-root .tracking-\[0\.27px\] {
  letter-spacing: 0.27px;
}

#body-root .tracking-\[0\.2px\] {
  letter-spacing: 0.2px;
}

#body-root .tracking-\[0\.36px\] {
  letter-spacing: 0.36px;
}

#body-root .tracking-\[0\.44px\] {
  letter-spacing: 0.44px;
}

#body-root .tracking-\[0\.48px\] {
  letter-spacing: 0.48px;
}

#body-root .tracking-\[0\.4px\] {
  letter-spacing: 0.4px;
}

#body-root .tracking-\[0\.52px\] {
  letter-spacing: 0.52px;
}

#body-root .tracking-\[1px\] {
  letter-spacing: 1px;
}

#body-root .tracking-normal {
  letter-spacing: 0em;
}

#body-root .tracking-wider {
  letter-spacing: 0.05em;
}

#body-root .tracking-widest {
  letter-spacing: 0.1em;
}

#body-root .\!text-\[\#000\] {
  --tw-text-opacity: 1 !important;
  color: rgb(0 0 0 / var(--tw-text-opacity)) !important;
}

#body-root .\!text-\[\#666666\] {
  --tw-text-opacity: 1 !important;
  color: rgb(102 102 102 / var(--tw-text-opacity)) !important;
}

#body-root .\!text-\[\#898886\] {
  --tw-text-opacity: 1 !important;
  color: rgb(137 136 134 / var(--tw-text-opacity)) !important;
}

#body-root .\!text-\[\#999999\] {
  --tw-text-opacity: 1 !important;
  color: rgb(153 153 153 / var(--tw-text-opacity)) !important;
}

#body-root .\!text-\[\#999\] {
  --tw-text-opacity: 1 !important;
  color: rgb(153 153 153 / var(--tw-text-opacity)) !important;
}

#body-root .\!text-\[\#CA2022\] {
  --tw-text-opacity: 1 !important;
  color: rgb(202 32 34 / var(--tw-text-opacity)) !important;
}

#body-root .\!text-\[\#fff\] {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}

#body-root .\!text-\[rgba\(0\2c 0\2c 0\2c 0\.6\)\] {
  color: rgba(0,0,0,0.6) !important;
}

#body-root .\!text-black {
  color: rgba(0, 0, 0, 1) !important;
}

#body-root .\!text-gray-200 {
  --tw-text-opacity: 1 !important;
  color: rgb(229 231 235 / var(--tw-text-opacity)) !important;
}

#body-root .\!text-gray-600 {
  --tw-text-opacity: 1 !important;
  color: rgb(102 102 102 / var(--tw-text-opacity)) !important;
}

#body-root .\!text-inherit {
  color: inherit !important;
}

#body-root .\!text-red-300 {
  --tw-text-opacity: 1 !important;
  color: rgb(202 102 103 / var(--tw-text-opacity)) !important;
}

#body-root .\!text-red-600 {
  --tw-text-opacity: 1 !important;
  color: rgb(220 38 38 / var(--tw-text-opacity)) !important;
}

#body-root .\!text-rh-dark {
  --tw-text-opacity: 1 !important;
  color: rgb(137 136 134 / var(--tw-text-opacity)) !important;
}

#body-root .\!text-rh-red {
  --tw-text-opacity: 1 !important;
  color: rgb(202 32 34 / var(--tw-text-opacity)) !important;
}

#body-root .\!text-rhBlack {
  --tw-text-opacity: 1 !important;
  color: rgb(0 0 0 / var(--tw-text-opacity)) !important;
}

#body-root .\!text-white {
  color: rgba(255, 255, 255, 1) !important;
}

#body-root .text-\[\#0000008a\] {
  color: #0000008a;
}

#body-root .text-\[\#000000\] {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

#body-root .text-\[\#000\] {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

#body-root .text-\[\#212121\] {
  --tw-text-opacity: 1;
  color: rgb(33 33 33 / var(--tw-text-opacity));
}

#body-root .text-\[\#404040\] {
  --tw-text-opacity: 1;
  color: rgb(64 64 64 / var(--tw-text-opacity));
}

#body-root .text-\[\#575757\] {
  --tw-text-opacity: 1;
  color: rgb(87 87 87 / var(--tw-text-opacity));
}

#body-root .text-\[\#666666\] {
  --tw-text-opacity: 1;
  color: rgb(102 102 102 / var(--tw-text-opacity));
}

#body-root .text-\[\#666\] {
  --tw-text-opacity: 1;
  color: rgb(102 102 102 / var(--tw-text-opacity));
}

#body-root .text-\[\#808080\] {
  --tw-text-opacity: 1;
  color: rgb(128 128 128 / var(--tw-text-opacity));
}

#body-root .text-\[\#898886\] {
  --tw-text-opacity: 1;
  color: rgb(137 136 134 / var(--tw-text-opacity));
}

#body-root .text-\[\#999999\] {
  --tw-text-opacity: 1;
  color: rgb(153 153 153 / var(--tw-text-opacity));
}

#body-root .text-\[\#CA2022\] {
  --tw-text-opacity: 1;
  color: rgb(202 32 34 / var(--tw-text-opacity));
}

#body-root .text-\[\#D4D2CF\] {
  --tw-text-opacity: 1;
  color: rgb(212 210 207 / var(--tw-text-opacity));
}

#body-root .text-\[\#FFF\] {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

#body-root .text-\[\#bdbdbd\] {
  --tw-text-opacity: 1;
  color: rgb(189 189 189 / var(--tw-text-opacity));
}

#body-root .text-\[\#ca2022\] {
  --tw-text-opacity: 1;
  color: rgb(202 32 34 / var(--tw-text-opacity));
}

#body-root .text-\[\#fff\] {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

#body-root .text-\[rgb\(0\2c 0\2c 0\)\] {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

#body-root .text-\[rgba\(0\2c 0\2c 0\2c 0\.54\)\] {
  color: rgba(0,0,0,0.54);
}

#body-root .text-\[rgba\(137\2c 136\2c 134\2c 1\)\] {
  color: rgba(137,136,134,1);
}

#body-root .text-black {
  color: rgba(0, 0, 0, 1);
}

#body-root .text-black\/90 {
  color: rgba(0, 0, 0, 0.9);
}

#body-root .text-darkGray-500 {
  --tw-text-opacity: 1;
  color: rgb(128 128 128 / var(--tw-text-opacity));
}

#body-root .text-gray-1 {
  --tw-text-opacity: 1;
  color: rgb(64 64 64 / var(--tw-text-opacity));
}

#body-root .text-gray-200 {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}

#body-root .text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(212 210 207 / var(--tw-text-opacity));
}

#body-root .text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(102 102 102 / var(--tw-text-opacity));
}

#body-root .text-inherit {
  color: inherit;
}

#body-root .text-red-300 {
  --tw-text-opacity: 1;
  color: rgb(202 102 103 / var(--tw-text-opacity));
}

#body-root .text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}

#body-root .text-red-600 {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity));
}

#body-root .text-red-700 {
  --tw-text-opacity: 1;
  color: rgb(185 28 28 / var(--tw-text-opacity));
}

#body-root .text-rh-dark {
  --tw-text-opacity: 1;
  color: rgb(137 136 134 / var(--tw-text-opacity));
}

#body-root .text-rh-darker {
  --tw-text-opacity: 1;
  color: rgb(32 31 31 / var(--tw-text-opacity));
}

#body-root .text-rh-red {
  --tw-text-opacity: 1;
  color: rgb(202 32 34 / var(--tw-text-opacity));
}

#body-root .text-rhBlack {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

#body-root .text-slate-600 {
  --tw-text-opacity: 1;
  color: rgb(71 85 105 / var(--tw-text-opacity));
}

#body-root .text-slate-700 {
  --tw-text-opacity: 1;
  color: rgb(51 65 85 / var(--tw-text-opacity));
}

#body-root .text-white {
  color: rgba(255, 255, 255, 1);
}

#body-root .\!underline {
  -webkit-text-decoration-line: underline !important;
          text-decoration-line: underline !important;
}

#body-root .underline {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}

#body-root .overline {
  -webkit-text-decoration-line: overline;
          text-decoration-line: overline;
}

#body-root .line-through {
  -webkit-text-decoration-line: line-through;
          text-decoration-line: line-through;
}

#body-root .\!no-underline {
  -webkit-text-decoration-line: none !important;
          text-decoration-line: none !important;
}

#body-root .no-underline {
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
}

#body-root .\!decoration-\[rgba\(0\2c 0\2c 0\2c 1\)\] {
  -webkit-text-decoration-color: rgba(0,0,0,1) !important;
          text-decoration-color: rgba(0,0,0,1) !important;
}

#body-root .decoration-\[rgba\(0\2c 0\2c 0\2c 1\)\] {
  -webkit-text-decoration-color: rgba(0,0,0,1);
          text-decoration-color: rgba(0,0,0,1);
}

#body-root .decoration-darkGray-500 {
  -webkit-text-decoration-color: #808080;
          text-decoration-color: #808080;
}

#body-root .decoration-inherit {
  -webkit-text-decoration-color: inherit;
          text-decoration-color: inherit;
}

#body-root .decoration-transparent {
  -webkit-text-decoration-color: rgba(0, 0, 0, 0);
          text-decoration-color: rgba(0, 0, 0, 0);
}

#body-root .decoration-solid {
  -webkit-text-decoration-style: solid;
          text-decoration-style: solid;
}

#body-root .\!decoration-\[0\.5px\] {
  text-decoration-thickness: 0.5px !important;
}

#body-root .decoration-0 {
  text-decoration-thickness: 0px;
}

#body-root .decoration-\[\.5px\] {
  text-decoration-thickness: .5px;
}

#body-root .decoration-\[0\.195px\] {
  text-decoration-thickness: 0.195px;
}

#body-root .decoration-\[0\.5px\] {
  text-decoration-thickness: 0.5px;
}

#body-root .decoration-auto {
  text-decoration-thickness: auto;
}

#body-root .\!underline-offset-2 {
  text-underline-offset: 2px !important;
}

#body-root .\!underline-offset-4 {
  text-underline-offset: 4px !important;
}

#body-root .\!underline-offset-\[1px\] {
  text-underline-offset: 1px !important;
}

#body-root .\!underline-offset-\[3px\] {
  text-underline-offset: 3px !important;
}

#body-root .\!underline-offset-auto {
  text-underline-offset: auto !important;
}

#body-root .underline-offset-2 {
  text-underline-offset: 2px;
}

#body-root .underline-offset-4 {
  text-underline-offset: 4px;
}

#body-root .underline-offset-\[25\%\] {
  text-underline-offset: 25%;
}

#body-root .underline-offset-\[2px\] {
  text-underline-offset: 2px;
}

#body-root .underline-offset-\[3px\] {
  text-underline-offset: 3px;
}

#body-root .underline-offset-\[4px\] {
  text-underline-offset: 4px;
}

#body-root .underline-offset-\[5px\] {
  text-underline-offset: 5px;
}

#body-root .underline-offset-auto {
  text-underline-offset: auto;
}

#body-root .\!opacity-0 {
  opacity: 0 !important;
}

#body-root .\!opacity-100 {
  opacity: 1 !important;
}

#body-root .opacity-0 {
  opacity: 0;
}

#body-root .opacity-100 {
  opacity: 1;
}

#body-root .opacity-20 {
  opacity: 0.2;
}

#body-root .opacity-30 {
  opacity: 0.3;
}

#body-root .opacity-40 {
  opacity: 0.4;
}

#body-root .opacity-50 {
  opacity: 0.5;
}

#body-root .opacity-60 {
  opacity: 0.6;
}

#body-root .opacity-85 {
  opacity: 0.85;
}

#body-root .opacity-\[0\.54\] {
  opacity: 0.54;
}

#body-root .opacity-\[0\.5\] {
  opacity: 0.5;
}

#body-root .opacity-\[0\.6\] {
  opacity: 0.6;
}

#body-root .\!mix-blend-normal {
  mix-blend-mode: normal !important;
}

#body-root .mix-blend-multiply {
  mix-blend-mode: multiply;
}

#body-root .mix-blend-darken {
  mix-blend-mode: darken;
}

#body-root .mix-blend-difference {
  mix-blend-mode: difference;
}

#body-root .shadow-\[0_0_8px_0_rgba\(0\2c 0\2c 0\2c 0\.25\)\] {
  --tw-shadow: 0 0 8px 0 rgba(0,0,0,0.25);
  --tw-shadow-colored: 0 0 8px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

#body-root .shadow-\[0_4px_4px_0px_rgba\(0\2c 0\2c 0\2c 0\.25\)\] {
  --tw-shadow: 0 4px 4px 0px rgba(0,0,0,0.25);
  --tw-shadow-colored: 0 4px 4px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

#body-root .shadow-\[0px_1px_4px_0px_rgba\(0\2c 0\2c 0\2c 0\.25\)\] {
  --tw-shadow: 0px 1px 4px 0px rgba(0,0,0,0.25);
  --tw-shadow-colored: 0px 1px 4px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

#body-root .shadow-\[0px_1px_4px_rgba\(0\2c 0\2c 0\2c 0\.25\)\] {
  --tw-shadow: 0px 1px 4px rgba(0,0,0,0.25);
  --tw-shadow-colored: 0px 1px 4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

#body-root .shadow-\[0px_2px_4px_rgba\(0\2c 0\2c 0\2c 0\.1\)\] {
  --tw-shadow: 0px 2px 4px rgba(0,0,0,0.1);
  --tw-shadow-colored: 0px 2px 4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

#body-root .shadow-\[0px_4px_4px_0px_rgba\(0\2c 0\2c 0\2c 0\.25\)\] {
  --tw-shadow: 0px 4px 4px 0px rgba(0,0,0,0.25);
  --tw-shadow-colored: 0px 4px 4px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

#body-root .shadow-\[rgba\(0\2c 0\2c 0\2c 0\.1\)_0px_0px_2px_2px\] {
  --tw-shadow: rgba(0,0,0,0.1) 0px 0px 2px 2px;
  --tw-shadow-colored: 0px 0px 2px 2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

#body-root .shadow-drawer {
  --tw-shadow: 0 0 2px 2px #0000001a;
  --tw-shadow-colored: 0 0 2px 2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

#body-root .shadow-drawer-shadow {
  --tw-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  --tw-shadow-colored: 0px 4px 4px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

#body-root .shadow-inner-stroke-pdp-configurator {
  --tw-shadow: inset 0 0 0 1.5px #000;
  --tw-shadow-colored: inset 0 0 0 1.5px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

#body-root .shadow-inner-stroke-thin-pdp-configurator {
  --tw-shadow: inset 0 0 0 0.75px #898886;
  --tw-shadow-colored: inset 0 0 0 0.75px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

#body-root .shadow-inner-stroke-thin-pdp-configurator-unavailable {
  --tw-shadow: inset 0 0 0 0.75px #D4D2CF;
  --tw-shadow-colored: inset 0 0 0 0.75px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

#body-root .shadow-popperBox {
  --tw-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  --tw-shadow-colored: 0px 4px 4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

#body-root .shadow-stytch-tooltip {
  --tw-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  --tw-shadow-colored: 0px 0px 2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

#body-root .shadow-swatchGroupsToolTip {
  --tw-shadow: 0 1px 3px 0 rgba(0,0,0,0.31);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

#body-root .outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

#body-root .outline {
  outline-style: solid;
}

#body-root .outline-0 {
  outline-width: 0px;
}

#body-root .outline-\[1px\] {
  outline-width: 1px;
}

#body-root .outline-black {
  outline-color: rgba(0, 0, 0, 1);
}

#body-root .ring {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

#body-root .blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

#body-root .grayscale {
  --tw-grayscale: grayscale(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

#body-root .invert {
  --tw-invert: invert(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

#body-root .filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

#body-root .\!transition-all {
  transition-property: all !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-duration: 150ms !important;
}

#body-root .\!transition-opacity {
  transition-property: opacity !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-duration: 150ms !important;
}

#body-root .transition {
  transition-property: color, background-color, border-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

#body-root .transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

#body-root .transition-colors {
  transition-property: color, background-color, border-color, fill, stroke, -webkit-text-decoration-color;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, -webkit-text-decoration-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

#body-root .transition-none {
  transition-property: none;
}

#body-root .transition-opacity {
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

#body-root .transition-transform {
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

#body-root .delay-0 {
  transition-delay: 0s;
}

#body-root .\!duration-500 {
  transition-duration: 500ms !important;
}

#body-root .\!duration-700 {
  transition-duration: 700ms !important;
}

#body-root .duration-0 {
  transition-duration: 0s;
}

#body-root .duration-1000 {
  transition-duration: 1000ms;
}

#body-root .duration-150 {
  transition-duration: 150ms;
}

#body-root .duration-200 {
  transition-duration: 200ms;
}

#body-root .duration-300 {
  transition-duration: 300ms;
}

#body-root .duration-500 {
  transition-duration: 500ms;
}

#body-root .duration-700 {
  transition-duration: 700ms;
}

#body-root .duration-\[0\.5s\] {
  transition-duration: 0.5s;
}

#body-root .duration-\[10ms\] {
  transition-duration: 10ms;
}

#body-root .duration-\[250ms\] {
  transition-duration: 250ms;
}

#body-root .\!ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
}

#body-root .ease-\[cubic-bezier\(0\.4\2c _0\2c _0\.2\2c _1\)\] {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

#body-root .ease-in {
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}

#body-root .ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

#body-root .ease-linear {
  transition-timing-function: linear;
}

#body-root .ease-out {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}

#body-root .\[-webkit-tap-highlight-color\:transparent\] {
  -webkit-tap-highlight-color: transparent;
}

#body-root .\[border-bottom\:0\.5px_solid_\#ddd\] {
  border-bottom: 0.5px solid #ddd;
}

#body-root .\[granite\:TableColumn\] {
  granite: TableColumn;
}

#body-root .\[text-decoration-thickness\:1px\] {
  text-decoration-thickness: 1px;
}

button {
  display: inline-block;
  border: none;
  padding: 1rem 2rem;
  margin: 0;
  text-decoration: none;
  background: transparent;
  font-family: sans-serif;
  font-size: 1rem;
  line-height: 1;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.MuiTypography-h2 {
  color: #000000;
  font-size: 2.25rem;
  font-family: RHSans-UltraThin, Helvetica, Arial;
  font-weight: 300;
  line-height: 2.5833333333333335rem;
  letter-spacing: -0.02em;
}

.MuiTypography-h3 {
  color: #000000;
  font-size: 1.6666666666666667rem;
  font-family: RHSans-UltraThin, Helvetica, Arial;
  font-weight: 400;
  line-height: 1.9166666666666667rem;
  letter-spacing: -0.02em;
}

.MuiSvgIcon-root {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 2rem;
  flex-shrink: 0;
}

#body-root .file\:border-0::file-selector-button {
  border-width: 0px;
}

#body-root .file\:bg-transparent::file-selector-button {
  background-color: rgba(0, 0, 0, 0);
}

#body-root .file\:text-sm::file-selector-button {
  font-size: 12.8px;
}

#body-root .file\:font-medium::file-selector-button {
  font-weight: 500;
}

#body-root .placeholder\:text-slate-400::placeholder {
  --tw-text-opacity: 1;
  color: rgb(148 163 184 / var(--tw-text-opacity));
}

#body-root .before\:absolute::before {
  content: var(--tw-content);
  position: absolute;
}

#body-root .before\:inset-x-0::before {
  content: var(--tw-content);
  left: 0px;
  right: 0px;
}

#body-root .before\:\!left-\[50\.71\%\]::before {
  content: var(--tw-content);
  left: 50.71% !important;
}

#body-root .before\:\!top-\[50\.28044\%\]::before {
  content: var(--tw-content);
  top: 50.28044% !important;
}

#body-root .before\:-top-\[1px\]::before {
  content: var(--tw-content);
  top: -1px;
}

#body-root .before\:left-0::before {
  content: var(--tw-content);
  left: 0px;
}

#body-root .before\:left-1::before {
  content: var(--tw-content);
  left: 4px;
}

#body-root .before\:left-2\/4::before {
  content: var(--tw-content);
  left: 50%;
}

#body-root .before\:right-0::before {
  content: var(--tw-content);
  right: 0px;
}

#body-root .before\:right-1::before {
  content: var(--tw-content);
  right: 4px;
}

#body-root .before\:top-0::before {
  content: var(--tw-content);
  top: 0px;
}

#body-root .before\:top-1\/2::before {
  content: var(--tw-content);
  top: 50%;
}

#body-root .before\:top-2\/4::before {
  content: var(--tw-content);
  top: 50%;
}

#body-root .before\:z-10::before {
  content: var(--tw-content);
  z-index: 10;
}

#body-root .before\:block::before {
  content: var(--tw-content);
  display: block;
}

#body-root .before\:h-1::before {
  content: var(--tw-content);
  height: 4px;
}

#body-root .before\:h-12::before {
  content: var(--tw-content);
  height: 48px;
}

#body-root .before\:h-3::before {
  content: var(--tw-content);
  height: 12px;
}

#body-root .before\:h-\[0\.5px\]::before {
  content: var(--tw-content);
  height: 0.5px;
}

#body-root .before\:h-\[1px\]::before {
  content: var(--tw-content);
  height: 1px;
}

#body-root .before\:h-\[22px\]::before {
  content: var(--tw-content);
  height: 22px;
}

#body-root .before\:h-\[6px\]::before {
  content: var(--tw-content);
  height: 6px;
}

#body-root .before\:h-px::before {
  content: var(--tw-content);
  height: 1px;
}

#body-root .before\:w-12::before {
  content: var(--tw-content);
  width: 48px;
}

#body-root .before\:w-3::before {
  content: var(--tw-content);
  width: 12px;
}

#body-root .before\:w-\[22px\]::before {
  content: var(--tw-content);
  width: 22px;
}

#body-root .before\:w-\[6px\]::before {
  content: var(--tw-content);
  width: 6px;
}

#body-root .before\:-translate-x-2\/4::before {
  content: var(--tw-content);
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

#body-root .before\:-translate-y-1\/2::before {
  content: var(--tw-content);
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

#body-root .before\:-translate-y-2\/4::before {
  content: var(--tw-content);
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

#body-root .before\:transform::before {
  content: var(--tw-content);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

#body-root .before\:border-0::before {
  content: var(--tw-content);
  border-width: 0px;
}

#body-root .before\:bg-\[rgba\(0\2c 0\2c 0\2c 0\.12\)\]::before {
  content: var(--tw-content);
  background-color: rgba(0,0,0,0.12);
}

#body-root .before\:bg-gray-500::before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(212 210 207 / var(--tw-bg-opacity));
}

#body-root .before\:bg-rh-dark::before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(137 136 134 / var(--tw-bg-opacity));
}

#body-root .before\:bg-swatch-overlay-gradient::before {
  content: var(--tw-content);
  background-image: linear-gradient(270deg, #130D03 0.57%, rgba(54, 47, 35, 0.60) 4.48%, rgba(194, 193, 191, 0.14) 46.96%, rgba(164, 164, 164, 0.19) 95.03%, rgba(0, 0, 0, 0.37) 100%);
}

#body-root .before\:opacity-0::before {
  content: var(--tw-content);
  opacity: 0;
}

#body-root .before\:opacity-100::before {
  content: var(--tw-content);
  opacity: 1;
}

#body-root .before\:opacity-40::before {
  content: var(--tw-content);
  opacity: 0.4;
}

#body-root .before\:opacity-\[0\.6\]::before {
  content: var(--tw-content);
  opacity: 0.6;
}

#body-root .before\:shadow-swatchGroupsToolTip::before {
  content: var(--tw-content);
  --tw-shadow: 0 1px 3px 0 rgba(0,0,0,0.31);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

#body-root .before\:transition-opacity::before {
  content: var(--tw-content);
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

#body-root .before\:content-\[\'\'\]::before {
  --tw-content: '';
  content: var(--tw-content);
}

#body-root .before\:content-\[\'\201C\'\]::before {
  --tw-content: '“';
  content: var(--tw-content);
}

#body-root .after\:absolute::after {
  content: var(--tw-content);
  position: absolute;
}

#body-root .after\:left-\[10px\]::after {
  content: var(--tw-content);
  left: 10px;
}

#body-root .after\:left-\[auto\]::after {
  content: var(--tw-content);
  left: auto;
}

#body-root .after\:right-\[10px\]::after {
  content: var(--tw-content);
  right: 10px;
}

#body-root .after\:right-\[auto\]::after {
  content: var(--tw-content);
  right: auto;
}

#body-root .after\:top-\[50\%\]::after {
  content: var(--tw-content);
  top: 50%;
}

#body-root .after\:inline-block::after {
  content: var(--tw-content);
  display: inline-block;
}

#body-root .after\:border-0::after {
  content: var(--tw-content);
  border-width: 0px;
}

#body-root .after\:border-b-2::after {
  content: var(--tw-content);
  border-bottom-width: 2px;
}

#body-root .after\:border-r-2::after {
  content: var(--tw-content);
  border-right-width: 2px;
}

#body-root .after\:border-solid::after {
  content: var(--tw-content);
  border-style: solid;
}

#body-root .after\:border-\[\#666\]::after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(102 102 102 / var(--tw-border-opacity));
}

#body-root .after\:p-1::after {
  content: var(--tw-content);
  padding: 4px;
}

#body-root .after\:text-\[25px\]::after {
  content: var(--tw-content);
  font-size: 25px;
}

#body-root .after\:content-\[\'\'\]::after {
  --tw-content: '';
  content: var(--tw-content);
}

#body-root .after\:content-\[\'\201D\'\]::after {
  --tw-content: '”';
  content: var(--tw-content);
}

#body-root .last\:mb-0:last-child {
  margin-bottom: 0px;
}

#body-root .last\:border-0:last-child {
  border-width: 0px;
}

#body-root .last\:border-b-\[0\.5px\]:last-child {
  border-bottom-width: 0.5px;
}

#body-root .last\:border-solid:last-child {
  border-style: solid;
}

#body-root .last\:border-gray-300:last-child {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

#body-root .checked\:\!border-\[\#CCCCCC\]:checked {
  --tw-border-opacity: 1 !important;
  border-color: rgb(204 204 204 / var(--tw-border-opacity)) !important;
}

#body-root .checked\:border-\[\#CCCCCC\]:checked {
  --tw-border-opacity: 1;
  border-color: rgb(204 204 204 / var(--tw-border-opacity));
}

#body-root .checked\:before\:bg-\[\#666666\]:checked::before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(102 102 102 / var(--tw-bg-opacity));
}

#body-root .focus-within\:outline:focus-within {
  outline-style: solid;
}

#body-root .focus-within\:outline-black:focus-within {
  outline-color: rgba(0, 0, 0, 1);
}

#body-root .hover\:scale-105:hover {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

#body-root .hover\:scale-\[1\.085\]:hover {
  --tw-scale-x: 1.085;
  --tw-scale-y: 1.085;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

#body-root .hover\:\!cursor-pointer:hover {
  cursor: pointer !important;
}

#body-root .hover\:cursor-pointer:hover {
  cursor: pointer;
}

#body-root .hover\:border-b-0:hover {
  border-bottom-width: 0px;
}

#body-root .hover\:\!border-\[\#404040\]:hover {
  --tw-border-opacity: 1 !important;
  border-color: rgb(64 64 64 / var(--tw-border-opacity)) !important;
}

#body-root .hover\:\!border-gray-1:hover {
  --tw-border-opacity: 1 !important;
  border-color: rgb(64 64 64 / var(--tw-border-opacity)) !important;
}

#body-root .hover\:border-gray-1:hover {
  --tw-border-opacity: 1;
  border-color: rgb(64 64 64 / var(--tw-border-opacity));
}

#body-root .hover\:border-gray-300:hover {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

#body-root .hover\:\!bg-\[\#404040\]:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(64 64 64 / var(--tw-bg-opacity)) !important;
}

#body-root .hover\:\!bg-\[\#FAFAFA\]:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(250 250 250 / var(--tw-bg-opacity)) !important;
}

#body-root .hover\:\!bg-gray-1:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(64 64 64 / var(--tw-bg-opacity)) !important;
}

#body-root .hover\:\!bg-transparent:hover {
  background-color: rgba(0, 0, 0, 0) !important;
}

#body-root .hover\:bg-\[\#F1F0ED\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(241 240 237 / var(--tw-bg-opacity));
}

#body-root .hover\:bg-\[\#FFFFFF80\]:hover {
  background-color: #FFFFFF80;
}

#body-root .hover\:bg-black\/80:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

#body-root .hover\:bg-gray-1:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(64 64 64 / var(--tw-bg-opacity));
}

#body-root .hover\:bg-gray-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(51 51 51 / var(--tw-bg-opacity));
}

#body-root .hover\:bg-rh-light:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(241 240 237 / var(--tw-bg-opacity));
}

#body-root .hover\:bg-slate-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(248 250 252 / var(--tw-bg-opacity));
}

#body-root .hover\:bg-transparent:hover {
  background-color: rgba(0, 0, 0, 0);
}

#body-root .hover\:bg-white\/90:hover {
  background-color: rgba(255, 255, 255, 0.9);
}

#body-root .hover\:\!text-black:hover {
  color: rgba(0, 0, 0, 1) !important;
}

#body-root .hover\:\!text-gray-800:hover {
  --tw-text-opacity: 1 !important;
  color: rgb(51 51 51 / var(--tw-text-opacity)) !important;
}

#body-root .hover\:text-\[\#000\]:hover {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

#body-root .hover\:text-black:hover {
  color: rgba(0, 0, 0, 1);
}

#body-root .hover\:text-gray-500:hover {
  --tw-text-opacity: 1;
  color: rgb(212 210 207 / var(--tw-text-opacity));
}

#body-root .hover\:text-gray-800:hover {
  --tw-text-opacity: 1;
  color: rgb(51 51 51 / var(--tw-text-opacity));
}

#body-root .hover\:text-white:hover {
  color: rgba(255, 255, 255, 1);
}

#body-root .hover\:underline:hover {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}

#body-root .hover\:\!no-underline:hover {
  -webkit-text-decoration-line: none !important;
          text-decoration-line: none !important;
}

#body-root .hover\:no-underline:hover {
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
}

#body-root .hover\:decoration-\[rgba\(0\2c 0\2c 0\2c 1\)\]:hover {
  -webkit-text-decoration-color: rgba(0,0,0,1);
          text-decoration-color: rgba(0,0,0,1);
}

#body-root .hover\:decoration-black:hover {
  -webkit-text-decoration-color: rgba(0, 0, 0, 1);
          text-decoration-color: rgba(0, 0, 0, 1);
}

#body-root .hover\:opacity-100:hover {
  opacity: 1;
}

#body-root .hover\:shadow-inner-stroke-hover-pdp-configurator:hover {
  --tw-shadow: inset 0 0 0 0.75px #000;
  --tw-shadow-colored: inset 0 0 0 0.75px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

#body-root .hover\:before\:opacity-0:hover::before {
  content: var(--tw-content);
  opacity: 0;
}

#body-root .focus\:\!bg-transparent:focus {
  background-color: rgba(0, 0, 0, 0) !important;
}

#body-root .focus\:bg-transparent:focus {
  background-color: rgba(0, 0, 0, 0);
}

#body-root .focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

#body-root .focus\:outline:focus {
  outline-style: solid;
}

#body-root .focus\:outline-\[1px\]:focus {
  outline-width: 1px;
}

#body-root .focus\:outline-offset-2:focus {
  outline-offset: 2px;
}

#body-root .focus\:outline-black:focus {
  outline-color: rgba(0, 0, 0, 1);
}

#body-root .focus-visible\:border:focus-visible {
  border-width: 1px;
}

#body-root .focus-visible\:border-solid:focus-visible {
  border-style: solid;
}

#body-root .focus-visible\:border-blue-500:focus-visible {
  --tw-border-opacity: 1;
  border-color: rgb(59 130 246 / var(--tw-border-opacity));
}

#body-root .focus-visible\:outline-none:focus-visible {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

#body-root .focus-visible\:outline:focus-visible {
  outline-style: solid;
}

#body-root .focus-visible\:outline-2:focus-visible {
  outline-width: 2px;
}

#body-root .focus-visible\:outline-\[1px\]:focus-visible {
  outline-width: 1px;
}

#body-root .focus-visible\:outline-\[\#015fcc\]:focus-visible {
  outline-color: #015fcc;
}

#body-root .focus-visible\:outline-blue-700:focus-visible {
  outline-color: #015FCC;
}

#body-root .focus-visible\:ring-0:focus-visible {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

#body-root .active\:-rotate-90:active {
  --tw-rotate: -90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

#body-root .active\:rotate-180:active {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

#body-root .disabled\:pointer-events-none:disabled {
  pointer-events: none;
}

#body-root .disabled\:cursor-not-allowed:disabled {
  cursor: not-allowed;
}

#body-root .disabled\:bg-\[\#DDD\]:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(221 221 221 / var(--tw-bg-opacity));
}

#body-root .disabled\:bg-rh-dark:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(137 136 134 / var(--tw-bg-opacity));
}

#body-root .disabled\:text-\[\#101010\]:disabled {
  --tw-text-opacity: 1;
  color: rgb(16 16 16 / var(--tw-text-opacity));
}

#body-root .disabled\:opacity-30:disabled {
  opacity: 0.3;
}

#body-root .disabled\:opacity-50:disabled {
  opacity: 0.5;
}

#body-root :is(.group\/item:hover .group-hover\/item\:visible) {
  visibility: visible;
}

#body-root :is(.group:hover .group-hover\:opacity-100) {
  opacity: 1;
}

@media (min-width: 375px) {
  #body-root .xs\:m-0 {
    margin: 0px;
  }

  #body-root .xs\:mx-6 {
    margin-left: 24px;
    margin-right: 24px;
  }

  #body-root .xs\:\!mt-2 {
    margin-top: 8px !important;
  }

  #body-root .xs\:mb-0 {
    margin-bottom: 0px;
  }

  #body-root .xs\:mb-6 {
    margin-bottom: 24px;
  }

  #body-root .xs\:mt-0 {
    margin-top: 0px;
  }

  #body-root .xs\:h-14 {
    height: 56px;
  }

  #body-root .xs\:h-auto {
    height: auto;
  }

  #body-root .xs\:\!max-h-\[26px\] {
    max-height: 26px !important;
  }

  #body-root .xs\:min-h-\[46px\] {
    min-height: 46px;
  }

  #body-root .xs\:\!w-\[343px\] {
    width: 343px !important;
  }

  #body-root .xs\:w-16 {
    width: 64px;
  }

  #body-root .xs\:w-3\/5 {
    width: 60%;
  }

  #body-root .xs\:w-\[216px\] {
    width: 216px;
  }

  #body-root .xs\:w-\[343px\] {
    width: 343px;
  }

  #body-root .xs\:w-\[50vw\] {
    width: 50vw;
  }

  #body-root .xs\:w-\[calc\(100\%-60px\)\] {
    width: calc(100% - 60px);
  }

  #body-root .xs\:min-w-12 {
    min-width: 48px;
  }

  #body-root .xs\:items-center {
    align-items: center;
  }

  #body-root .xs\:\!justify-start {
    justify-content: flex-start !important;
  }

  #body-root .xs\:justify-start {
    justify-content: flex-start;
  }

  #body-root .xs\:\!justify-end {
    justify-content: flex-end !important;
  }

  #body-root .xs\:p-10 {
    padding: 40px;
  }

  #body-root .xs\:\!px-10 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }

  #body-root .xs\:\!py-12 {
    padding-top: 48px !important;
    padding-bottom: 48px !important;
  }

  #body-root .xs\:pb-6 {
    padding-bottom: 24px;
  }

  #body-root .xs\:pl-0 {
    padding-left: 0px;
  }

  #body-root .xs\:pr-11 {
    padding-right: 44px;
  }

  #body-root .xs\:\!text-\[10px\] {
    font-size: 10px !important;
  }

  #body-root .xs\:text-\[10px\] {
    font-size: 10px;
  }

  #body-root .xs\:text-\[16px\] {
    font-size: 16px;
  }

  #body-root .xs\:\!uppercase {
    text-transform: uppercase !important;
  }

  #body-root .xs\:leading-\[20px\] {
    line-height: 20px;
  }

  #body-root .xs\:\[width\:unset\] {
    width: unset;
  }
}

@media (max-width: 375px) {
  #body-root .xsDown\:mb-0 {
    margin-bottom: 0px;
  }

  #body-root .xsDown\:mt-0 {
    margin-top: 0px;
  }

  #body-root .xsDown\:w-\[calc\(100\%-60px\)\] {
    width: calc(100% - 60px);
  }
}

@media (max-width: 326px) {
  #body-root .xxsDown\:pl-1 {
    padding-left: 4px;
  }

  #body-root .xxsDown\:pl-2 {
    padding-left: 8px;
  }

  #body-root .xxsDown\:text-center {
    text-align: center;
  }
}

@media (min-width: 640px) {
  #body-root .xsm\:\!mr-\[16px\] {
    margin-right: 16px !important;
  }

  #body-root .xsm\:h-6 {
    height: 24px;
  }

  #body-root .xsm\:w-6 {
    width: 24px;
  }
}

@media (min-width: 768px) {
  #body-root .sm\:\!sticky {
    position: sticky !important;
  }

  #body-root .sm\:\!top-0 {
    top: 0px !important;
  }

  #body-root .sm\:right-4 {
    right: 16px;
  }

  #body-root .sm\:top-\[unset\] {
    top: unset;
  }

  #body-root .sm\:order-1 {
    order: 1;
  }

  #body-root .sm\:order-2 {
    order: 2;
  }

  #body-root .sm\:order-3 {
    order: 3;
  }

  #body-root .sm\:order-none {
    order: 0;
  }

  #body-root .sm\:-mx-\[60px\] {
    margin-left: -60px;
    margin-right: -60px;
  }

  #body-root .sm\:mx-8 {
    margin-left: 32px;
    margin-right: 32px;
  }

  #body-root .sm\:my-2 {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  #body-root .sm\:\!mb-0 {
    margin-bottom: 0px !important;
  }

  #body-root .sm\:\!mb-8 {
    margin-bottom: 32px !important;
  }

  #body-root .sm\:\!mb-\[160px\] {
    margin-bottom: 160px !important;
  }

  #body-root .sm\:\!mr-1 {
    margin-right: 4px !important;
  }

  #body-root .sm\:\!mr-\[0px\] {
    margin-right: 0px !important;
  }

  #body-root .sm\:\!mt-4 {
    margin-top: 16px !important;
  }

  #body-root .sm\:\!mt-\[24px\] {
    margin-top: 24px !important;
  }

  #body-root .sm\:mb-0 {
    margin-bottom: 0px;
  }

  #body-root .sm\:mb-6 {
    margin-bottom: 24px;
  }

  #body-root .sm\:mb-\[32px\] {
    margin-bottom: 32px;
  }

  #body-root .sm\:mr-0 {
    margin-right: 0px;
  }

  #body-root .sm\:mt-0 {
    margin-top: 0px;
  }

  #body-root .sm\:mt-2 {
    margin-top: 8px;
  }

  #body-root .sm\:mt-3 {
    margin-top: 12px;
  }

  #body-root .sm\:mt-5 {
    margin-top: 20px;
  }

  #body-root .sm\:mt-\[11px\] {
    margin-top: 11px;
  }

  #body-root .sm\:mt-\[52px\] {
    margin-top: 52px;
  }

  #body-root .sm\:mt-\[7px\] {
    margin-top: 7px;
  }

  #body-root .sm\:block {
    display: block;
  }

  #body-root .sm\:flex {
    display: flex;
  }

  #body-root .sm\:hidden {
    display: none;
  }

  #body-root .sm\:aspect-\[2\.5\/1\] {
    aspect-ratio: 2.5/1;
  }

  #body-root .sm\:\!h-6 {
    height: 24px !important;
  }

  #body-root .sm\:\!h-\[26px\] {
    height: 26px !important;
  }

  #body-root .sm\:\!h-\[48px\] {
    height: 48px !important;
  }

  #body-root .sm\:\!h-\[67px\] {
    height: 67px !important;
  }

  #body-root .sm\:h-14 {
    height: 56px;
  }

  #body-root .sm\:h-6 {
    height: 24px;
  }

  #body-root .sm\:h-\[124px\] {
    height: 124px;
  }

  #body-root .sm\:h-\[144px\] {
    height: 144px;
  }

  #body-root .sm\:h-\[206px\] {
    height: 206px;
  }

  #body-root .sm\:h-\[279px\] {
    height: 279px;
  }

  #body-root .sm\:h-\[50px\] {
    height: 50px;
  }

  #body-root .sm\:h-\[56px\] {
    height: 56px;
  }

  #body-root .sm\:h-auto {
    height: auto;
  }

  #body-root .sm\:\!max-h-\[13px\] {
    max-height: 13px !important;
  }

  #body-root .sm\:max-h-14 {
    max-height: 56px;
  }

  #body-root .sm\:min-h-\[67px\] {
    min-height: 67px;
  }

  #body-root .sm\:\!w-32 {
    width: 128px !important;
  }

  #body-root .sm\:\!w-6 {
    width: 24px !important;
  }

  #body-root .sm\:\!w-\[143px\] {
    width: 143px !important;
  }

  #body-root .sm\:\!w-\[26px\] {
    width: 26px !important;
  }

  #body-root .sm\:\!w-\[370px\] {
    width: 370px !important;
  }

  #body-root .sm\:\!w-\[582px\] {
    width: 582px !important;
  }

  #body-root .sm\:\!w-\[70px\] {
    width: 70px !important;
  }

  #body-root .sm\:w-1\/2 {
    width: 50%;
  }

  #body-root .sm\:w-6 {
    width: 24px;
  }

  #body-root .sm\:w-8 {
    width: 32px;
  }

  #body-root .sm\:w-9 {
    width: 36px;
  }

  #body-root .sm\:w-\[0\.5px\] {
    width: 0.5px;
  }

  #body-root .sm\:w-\[100\%\] {
    width: 100%;
  }

  #body-root .sm\:w-\[137px\] {
    width: 137px;
  }

  #body-root .sm\:w-\[178px\] {
    width: 178px;
  }

  #body-root .sm\:w-\[216px\] {
    width: 216px;
  }

  #body-root .sm\:w-\[296px\] {
    width: 296px;
  }

  #body-root .sm\:w-\[330px\] {
    width: 330px;
  }

  #body-root .sm\:w-\[336px\] {
    width: 336px;
  }

  #body-root .sm\:w-\[339px\] {
    width: 339px;
  }

  #body-root .sm\:w-\[388px\] {
    width: 388px;
  }

  #body-root .sm\:w-\[50px\] {
    width: 50px;
  }

  #body-root .sm\:w-\[50vw\] {
    width: 50vw;
  }

  #body-root .sm\:w-\[523px\] {
    width: 523px;
  }

  #body-root .sm\:w-\[582px\] {
    width: 582px;
  }

  #body-root .sm\:w-\[66\.66vw\] {
    width: 66.66vw;
  }

  #body-root .sm\:w-\[704px\] {
    width: 704px;
  }

  #body-root .sm\:w-\[unset\] {
    width: unset;
  }

  #body-root .sm\:w-full {
    width: 100%;
  }

  #body-root .sm\:min-w-\[212px\] {
    min-width: 212px;
  }

  #body-root .sm\:min-w-\[70px\] {
    min-width: 70px;
  }

  #body-root .sm\:max-w-\[397px\] {
    max-width: 397px;
  }

  #body-root .sm\:flex-1 {
    flex: 1 1 0%;
  }

  #body-root .sm\:basis-1\/3 {
    flex-basis: 33.333333%;
  }

  #body-root .sm\:translate-y-\[27\%\] {
    --tw-translate-y: 27%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  #body-root .sm\:translate-y-\[50\%\] {
    --tw-translate-y: 50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  #body-root .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  #body-root .sm\:flex-row {
    flex-direction: row;
  }

  #body-root .sm\:flex-wrap {
    flex-wrap: wrap;
  }

  #body-root .sm\:items-start {
    align-items: flex-start;
  }

  #body-root .sm\:justify-normal {
    justify-content: normal;
  }

  #body-root .sm\:\!justify-start {
    justify-content: flex-start !important;
  }

  #body-root .sm\:justify-start {
    justify-content: flex-start;
  }

  #body-root .sm\:\!justify-end {
    justify-content: flex-end !important;
  }

  #body-root .sm\:justify-center {
    justify-content: center;
  }

  #body-root .sm\:justify-between {
    justify-content: space-between;
  }

  #body-root .sm\:gap-10 {
    gap: 40px;
  }

  #body-root .sm\:gap-12 {
    gap: 48px;
  }

  #body-root .sm\:gap-\[16px\] {
    gap: 16px;
  }

  #body-root .sm\:gap-\[60px\] {
    gap: 60px;
  }

  #body-root .sm\:\!gap-x-2 {
    column-gap: 8px !important;
  }

  #body-root .sm\:\!gap-y-1 {
    row-gap: 4px !important;
  }

  #body-root .sm\:gap-x-8 {
    column-gap: 32px;
  }

  #body-root .sm\:gap-y-12 {
    row-gap: 48px;
  }

  #body-root .sm\:gap-y-\[16px\] {
    row-gap: 16px;
  }

  #body-root .sm\:overflow-x-hidden {
    overflow-x: hidden;
  }

  #body-root .sm\:whitespace-nowrap {
    white-space: nowrap;
  }

  #body-root .sm\:\!p-\[10px\] {
    padding: 10px !important;
  }

  #body-root .sm\:p-0 {
    padding: 0px;
  }

  #body-root .sm\:\!px-10 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }

  #body-root .sm\:\!px-6 {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }

  #body-root .sm\:\!px-\[30px\] {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  #body-root .sm\:\!py-12 {
    padding-top: 48px !important;
    padding-bottom: 48px !important;
  }

  #body-root .sm\:\!py-6 {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }

  #body-root .sm\:\!py-\[60px\] {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }

  #body-root .sm\:px-10 {
    padding-left: 40px;
    padding-right: 40px;
  }

  #body-root .sm\:px-12 {
    padding-left: 48px;
    padding-right: 48px;
  }

  #body-root .sm\:px-6 {
    padding-left: 24px;
    padding-right: 24px;
  }

  #body-root .sm\:px-8 {
    padding-left: 32px;
    padding-right: 32px;
  }

  #body-root .sm\:px-\[30px\] {
    padding-left: 30px;
    padding-right: 30px;
  }

  #body-root .sm\:px-\[32px\] {
    padding-left: 32px;
    padding-right: 32px;
  }

  #body-root .sm\:px-\[60px\] {
    padding-left: 60px;
    padding-right: 60px;
  }

  #body-root .sm\:px-\[66px\] {
    padding-left: 66px;
    padding-right: 66px;
  }

  #body-root .sm\:py-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  #body-root .sm\:py-6 {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  #body-root .sm\:py-\[50px\] {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  #body-root .sm\:py-\[60px\] {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  #body-root .sm\:\!pb-12 {
    padding-bottom: 48px !important;
  }

  #body-root .sm\:pb-0 {
    padding-bottom: 0px;
  }

  #body-root .sm\:pb-4 {
    padding-bottom: 16px;
  }

  #body-root .sm\:pb-8 {
    padding-bottom: 32px;
  }

  #body-root .sm\:pb-9 {
    padding-bottom: 36px;
  }

  #body-root .sm\:pb-\[12px\] {
    padding-bottom: 12px;
  }

  #body-root .sm\:pb-\[31px\] {
    padding-bottom: 31px;
  }

  #body-root .sm\:pl-0 {
    padding-left: 0px;
  }

  #body-root .sm\:pl-8 {
    padding-left: 32px;
  }

  #body-root .sm\:pr-8 {
    padding-right: 32px;
  }

  #body-root .sm\:pt-0 {
    padding-top: 0px;
  }

  #body-root .sm\:pt-2\.5 {
    padding-top: 10px;
  }

  #body-root .sm\:pt-\[10px\] {
    padding-top: 10px;
  }

  #body-root .sm\:text-left {
    text-align: left;
  }

  #body-root .sm\:\!text-\[20px\] {
    font-size: 20px !important;
  }

  #body-root .sm\:\!text-\[8px\] {
    font-size: 8px !important;
  }

  #body-root .sm\:text-\[13px\] {
    font-size: 13px;
  }

  #body-root .sm\:text-\[16px\] {
    font-size: 16px;
  }

  #body-root .sm\:\!normal-case {
    text-transform: none !important;
  }

  #body-root .sm\:leading-5 {
    line-height: 20px;
  }

  #body-root .sm\:leading-\[20px\] {
    line-height: 20px;
  }
}

@media (min-width: 992px) {
  #body-root .md\:\!static {
    position: static !important;
  }

  #body-root .md\:absolute {
    position: absolute;
  }

  #body-root .md\:\!relative {
    position: relative !important;
  }

  #body-root .md\:relative {
    position: relative;
  }

  #body-root .md\:-right-10 {
    right: -40px;
  }

  #body-root .md\:bottom-0 {
    bottom: 0px;
  }

  #body-root .md\:bottom-8 {
    bottom: 32px;
  }

  #body-root .md\:bottom-\[57px\] {
    bottom: 57px;
  }

  #body-root .md\:bottom-\[79px\] {
    bottom: 79px;
  }

  #body-root .md\:left-1\/2 {
    left: 50%;
  }

  #body-root .md\:top-\[70\%\] {
    top: 70%;
  }

  #body-root .md\:row-start-1 {
    grid-row-start: 1;
  }

  #body-root .md\:\!m-\[32px\] {
    margin: 32px !important;
  }

  #body-root .md\:mx-10 {
    margin-left: 40px;
    margin-right: 40px;
  }

  #body-root .md\:my-2\.5 {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  #body-root .md\:my-\[21px\] {
    margin-top: 21px;
    margin-bottom: 21px;
  }

  #body-root .md\:\!mb-0 {
    margin-bottom: 0px !important;
  }

  #body-root .md\:\!mb-4 {
    margin-bottom: 16px !important;
  }

  #body-root .md\:\!mr-0 {
    margin-right: 0px !important;
  }

  #body-root .md\:\!mr-4 {
    margin-right: 16px !important;
  }

  #body-root .md\:\!mt-0 {
    margin-top: 0px !important;
  }

  #body-root .md\:\!mt-\[2px\] {
    margin-top: 2px !important;
  }

  #body-root .md\:mb-12 {
    margin-bottom: 48px;
  }

  #body-root .md\:mb-3 {
    margin-bottom: 12px;
  }

  #body-root .md\:mb-9 {
    margin-bottom: 36px;
  }

  #body-root .md\:mb-\[0px\] {
    margin-bottom: 0px;
  }

  #body-root .md\:mb-\[24px\] {
    margin-bottom: 24px;
  }

  #body-root .md\:mb-\[29px\] {
    margin-bottom: 29px;
  }

  #body-root .md\:mb-\[6px\] {
    margin-bottom: 6px;
  }

  #body-root .md\:ml-\[40px\] {
    margin-left: 40px;
  }

  #body-root .md\:mt-0 {
    margin-top: 0px;
  }

  #body-root .md\:mt-12 {
    margin-top: 48px;
  }

  #body-root .md\:mt-2\.5 {
    margin-top: 10px;
  }

  #body-root .md\:mt-3 {
    margin-top: 12px;
  }

  #body-root .md\:mt-4 {
    margin-top: 16px;
  }

  #body-root .md\:mt-\[40\%\] {
    margin-top: 40%;
  }

  #body-root .md\:mt-\[44px\] {
    margin-top: 44px;
  }

  #body-root .md\:mt-\[45px\] {
    margin-top: 45px;
  }

  #body-root .md\:mt-\[48px\] {
    margin-top: 48px;
  }

  #body-root .md\:mt-auto {
    margin-top: auto;
  }

  #body-root .md\:block {
    display: block;
  }

  #body-root .md\:inline-block {
    display: inline-block;
  }

  #body-root .md\:flex {
    display: flex;
  }

  #body-root .md\:hidden {
    display: none;
  }

  #body-root .md\:\!h-6 {
    height: 24px !important;
  }

  #body-root .md\:\!h-\[270px\] {
    height: 270px !important;
  }

  #body-root .md\:\!h-\[48px\] {
    height: 48px !important;
  }

  #body-root .md\:h-14 {
    height: 56px;
  }

  #body-root .md\:h-\[100\%\] {
    height: 100%;
  }

  #body-root .md\:h-\[132px\] {
    height: 132px;
  }

  #body-root .md\:h-auto {
    height: auto;
  }

  #body-root .md\:\!max-h-\[39px\] {
    max-height: 39px !important;
  }

  #body-root .md\:min-h-\[26px\] {
    min-height: 26px;
  }

  #body-root .md\:\!w-6 {
    width: 24px !important;
  }

  #body-root .md\:\!w-\[270px\] {
    width: 270px !important;
  }

  #body-root .md\:\!w-\[317px\] {
    width: 317px !important;
  }

  #body-root .md\:\!w-\[440px\] {
    width: 440px !important;
  }

  #body-root .md\:\!w-\[768px\] {
    width: 768px !important;
  }

  #body-root .md\:w-1\/2 {
    width: 50%;
  }

  #body-root .md\:w-1\/3 {
    width: 33.333333%;
  }

  #body-root .md\:w-10 {
    width: 40px;
  }

  #body-root .md\:w-\[250px\] {
    width: 250px;
  }

  #body-root .md\:w-\[253px\] {
    width: 253px;
  }

  #body-root .md\:w-\[280px\] {
    width: 280px;
  }

  #body-root .md\:w-\[317px\] {
    width: 317px;
  }

  #body-root .md\:w-\[33\.33vw\] {
    width: 33.33vw;
  }

  #body-root .md\:w-\[355px\] {
    width: 355px;
  }

  #body-root .md\:w-\[376px\] {
    width: 376px;
  }

  #body-root .md\:w-\[400px\] {
    width: 400px;
  }

  #body-root .md\:w-\[436px\] {
    width: 436px;
  }

  #body-root .md\:w-\[50\%\] {
    width: 50%;
  }

  #body-root .md\:w-\[50vw\] {
    width: 50vw;
  }

  #body-root .md\:w-\[523px\] {
    width: 523px;
  }

  #body-root .md\:w-\[calc\(100\%-250px\)\] {
    width: calc(100% - 250px);
  }

  #body-root .md\:w-auto {
    width: auto;
  }

  #body-root .md\:w-full {
    width: 100%;
  }

  #body-root .md\:\!max-w-\[332px\] {
    max-width: 332px !important;
  }

  #body-root .md\:\!max-w-\[800px\] {
    max-width: 800px !important;
  }

  #body-root .md\:max-w-\[450px\] {
    max-width: 450px;
  }

  #body-root .md\:max-w-\[476px\] {
    max-width: 476px;
  }

  #body-root .md\:max-w-none {
    max-width: none;
  }

  #body-root .md\:flex-grow {
    flex-grow: 1;
  }

  #body-root .md\:basis-1\/3 {
    flex-basis: 33.333333%;
  }

  #body-root .md\:basis-1\/4 {
    flex-basis: 25%;
  }

  #body-root .md\:basis-1\/5 {
    flex-basis: 20%;
  }

  #body-root .md\:-translate-x-1\/2 {
    --tw-translate-x: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  #body-root .md\:-translate-y-\[100\%\] {
    --tw-translate-y: -100%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  #body-root .md\:-translate-y-\[150\%\] {
    --tw-translate-y: -150%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  #body-root .md\:translate-y-\[0\%\] {
    --tw-translate-y: 0%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  #body-root .md\:transform {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  #body-root .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  #body-root .md\:flex-row {
    flex-direction: row;
  }

  #body-root .md\:items-start {
    align-items: flex-start;
  }

  #body-root .md\:items-end {
    align-items: flex-end;
  }

  #body-root .md\:\!justify-end {
    justify-content: flex-end !important;
  }

  #body-root .md\:justify-end {
    justify-content: flex-end;
  }

  #body-root .md\:justify-center {
    justify-content: center;
  }

  #body-root .md\:\!gap-0 {
    gap: 0px !important;
  }

  #body-root .md\:gap-1\.5 {
    gap: 6px;
  }

  #body-root .md\:gap-6 {
    gap: 24px;
  }

  #body-root .md\:\!gap-x-2 {
    column-gap: 8px !important;
  }

  #body-root .md\:\!gap-y-1 {
    row-gap: 4px !important;
  }

  #body-root .md\:gap-x-10 {
    column-gap: 40px;
  }

  #body-root .md\:gap-y-\[60px\] {
    row-gap: 60px;
  }

  #body-root .md\:place-self-end {
    place-self: end;
  }

  #body-root .md\:self-end {
    align-self: flex-end;
  }

  #body-root .md\:justify-self-start {
    justify-self: start;
  }

  #body-root .md\:\!overflow-auto {
    overflow: auto !important;
  }

  #body-root .md\:overflow-hidden {
    overflow: hidden;
  }

  #body-root .md\:overflow-y-auto {
    overflow-y: auto;
  }

  #body-root .md\:\!overflow-x-hidden {
    overflow-x: hidden !important;
  }

  #body-root .md\:whitespace-normal {
    white-space: normal;
  }

  #body-root .md\:border-r {
    border-right-width: 1px;
  }

  #body-root .md\:\!border-white {
    border-color: rgba(255, 255, 255, 1) !important;
  }

  #body-root .md\:border-\[\#F3F3F3\] {
    --tw-border-opacity: 1;
    border-color: rgb(243 243 243 / var(--tw-border-opacity));
  }

  #body-root .md\:\!p-\[32px\] {
    padding: 32px !important;
  }

  #body-root .md\:\!p-\[48px\] {
    padding: 48px !important;
  }

  #body-root .md\:\!p-\[60px\] {
    padding: 60px !important;
  }

  #body-root .md\:p-\[14\%\] {
    padding: 14%;
  }

  #body-root .md\:\!px-10 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }

  #body-root .md\:\!px-12 {
    padding-left: 48px !important;
    padding-right: 48px !important;
  }

  #body-root .md\:\!px-8 {
    padding-left: 32px !important;
    padding-right: 32px !important;
  }

  #body-root .md\:\!py-4 {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }

  #body-root .md\:\!py-8 {
    padding-top: 32px !important;
    padding-bottom: 32px !important;
  }

  #body-root .md\:px-0 {
    padding-left: 0px;
    padding-right: 0px;
  }

  #body-root .md\:px-10 {
    padding-left: 40px;
    padding-right: 40px;
  }

  #body-root .md\:px-\[40px\] {
    padding-left: 40px;
    padding-right: 40px;
  }

  #body-root .md\:px-\[60px\] {
    padding-left: 60px;
    padding-right: 60px;
  }

  #body-root .md\:px-\[90px\] {
    padding-left: 90px;
    padding-right: 90px;
  }

  #body-root .md\:py-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  #body-root .md\:py-10 {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  #body-root .md\:py-14 {
    padding-top: 56px;
    padding-bottom: 56px;
  }

  #body-root .md\:py-\[60px\] {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  #body-root .md\:\!pb-0 {
    padding-bottom: 0px !important;
  }

  #body-root .md\:\!pb-12 {
    padding-bottom: 48px !important;
  }

  #body-root .md\:\!pr-4 {
    padding-right: 16px !important;
  }

  #body-root .md\:\!pt-0 {
    padding-top: 0px !important;
  }

  #body-root .md\:pb-12 {
    padding-bottom: 48px;
  }

  #body-root .md\:pb-3 {
    padding-bottom: 12px;
  }

  #body-root .md\:pb-4 {
    padding-bottom: 16px;
  }

  #body-root .md\:pl-10 {
    padding-left: 40px;
  }

  #body-root .md\:pl-6 {
    padding-left: 24px;
  }

  #body-root .md\:pr-10 {
    padding-right: 40px;
  }

  #body-root .md\:pr-5 {
    padding-right: 20px;
  }

  #body-root .md\:pt-0 {
    padding-top: 0px;
  }

  #body-root .md\:pt-\[15px\] {
    padding-top: 15px;
  }

  #body-root .md\:pt-\[6px\] {
    padding-top: 6px;
  }

  #body-root .md\:text-left {
    text-align: left;
  }

  #body-root .md\:text-center {
    text-align: center;
  }

  #body-root .md\:\!text-\[10px\] {
    font-size: 10px !important;
  }

  #body-root .md\:\!text-\[11px\] {
    font-size: 11px !important;
  }

  #body-root .md\:\!text-\[32px\] {
    font-size: 32px !important;
  }

  #body-root .md\:text-\[10px\] {
    font-size: 10px;
  }

  #body-root .md\:text-\[12px\] {
    font-size: 12px;
  }

  #body-root .md\:text-\[20px\] {
    font-size: 20px;
  }

  #body-root .md\:text-sm {
    font-size: 12.8px;
  }

  #body-root .md\:\!uppercase {
    text-transform: uppercase !important;
  }

  #body-root .md\:\!text-white {
    color: rgba(255, 255, 255, 1) !important;
  }
}

@media (min-width: 1200px) {
  #body-root .lg\:bottom-4 {
    bottom: 16px;
  }

  #body-root .lg\:bottom-\[-5px\] {
    bottom: -5px;
  }

  #body-root .lg\:bottom-\[100px\] {
    bottom: 100px;
  }

  #body-root .lg\:bottom-\[116px\] {
    bottom: 116px;
  }

  #body-root .lg\:mx-\[51px\] {
    margin-left: 51px;
    margin-right: 51px;
  }

  #body-root .lg\:\!ml-\[25px\] {
    margin-left: 25px !important;
  }

  #body-root .lg\:\!mr-10 {
    margin-right: 40px !important;
  }

  #body-root .lg\:\!mr-\[25px\] {
    margin-right: 25px !important;
  }

  #body-root .lg\:mb-8 {
    margin-bottom: 32px;
  }

  #body-root .lg\:mb-\[24px\] {
    margin-bottom: 24px;
  }

  #body-root .lg\:mb-\[60px\] {
    margin-bottom: 60px;
  }

  #body-root .lg\:mr-10 {
    margin-right: 40px;
  }

  #body-root .lg\:mt-0 {
    margin-top: 0px;
  }

  #body-root .lg\:mt-5 {
    margin-top: 20px;
  }

  #body-root .lg\:mt-\[44px\] {
    margin-top: 44px;
  }

  #body-root .lg\:\!h-4 {
    height: 16px !important;
  }

  #body-root .lg\:\!h-\[18rem\] {
    height: 18rem !important;
  }

  #body-root .lg\:\!h-\[300px\] {
    height: 300px !important;
  }

  #body-root .lg\:\!h-\[85px\] {
    height: 85px !important;
  }

  #body-root .lg\:\!max-h-\[26px\] {
    max-height: 26px !important;
  }

  #body-root .lg\:\!w-\[300px\] {
    width: 300px !important;
  }

  #body-root .lg\:\!w-\[347px\] {
    width: 347px !important;
  }

  #body-root .lg\:w-\[280px\] {
    width: 280px;
  }

  #body-root .lg\:w-\[323px\] {
    width: 323px;
  }

  #body-root .lg\:w-\[347px\] {
    width: 347px;
  }

  #body-root .lg\:w-\[498px\] {
    width: 498px;
  }

  #body-root .lg\:w-\[533px\] {
    width: 533px;
  }

  #body-root .lg\:w-\[540px\] {
    width: 540px;
  }

  #body-root .lg\:w-full {
    width: 100%;
  }

  #body-root .lg\:min-w-\[1006px\] {
    min-width: 1006px;
  }

  #body-root .lg\:\!max-w-\[362px\] {
    max-width: 362px !important;
  }

  #body-root .lg\:\!max-w-\[860px\] {
    max-width: 860px !important;
  }

  #body-root .lg\:max-w-\[706px\] {
    max-width: 706px;
  }

  #body-root .lg\:basis-1\/3 {
    flex-basis: 33.333333%;
  }

  #body-root .lg\:basis-1\/4 {
    flex-basis: 25%;
  }

  #body-root .lg\:basis-1\/5 {
    flex-basis: 20%;
  }

  #body-root .lg\:gap-10 {
    gap: 40px;
  }

  #body-root .lg\:gap-12 {
    gap: 48px;
  }

  #body-root .lg\:gap-16 {
    gap: 64px;
  }

  #body-root .lg\:gap-2 {
    gap: 8px;
  }

  #body-root .lg\:gap-8 {
    gap: 32px;
  }

  #body-root .lg\:\!gap-x-2 {
    column-gap: 8px !important;
  }

  #body-root .lg\:\!gap-y-1 {
    row-gap: 4px !important;
  }

  #body-root .lg\:gap-y-20 {
    row-gap: 80px;
  }

  #body-root .lg\:whitespace-normal {
    white-space: normal;
  }

  #body-root .lg\:whitespace-nowrap {
    white-space: nowrap;
  }

  #body-root .lg\:\!px-\[32px\] {
    padding-left: 32px !important;
    padding-right: 32px !important;
  }

  #body-root .lg\:\!px-\[60px\] {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }

  #body-root .lg\:\!py-10 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  #body-root .lg\:px-10 {
    padding-left: 40px;
    padding-right: 40px;
  }

  #body-root .lg\:px-20 {
    padding-left: 80px;
    padding-right: 80px;
  }

  #body-root .lg\:px-\[18\%\] {
    padding-left: 18%;
    padding-right: 18%;
  }

  #body-root .lg\:px-\[40px\] {
    padding-left: 40px;
    padding-right: 40px;
  }

  #body-root .lg\:px-\[6\%\] {
    padding-left: 6%;
    padding-right: 6%;
  }

  #body-root .lg\:py-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  #body-root .lg\:py-\[100px\] {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  #body-root .lg\:\!pl-0 {
    padding-left: 0px !important;
  }

  #body-root .lg\:\!pl-10 {
    padding-left: 40px !important;
  }

  #body-root .lg\:\!pl-\[70px\] {
    padding-left: 70px !important;
  }

  #body-root .lg\:\!pl-\[75px\] {
    padding-left: 75px !important;
  }

  #body-root .lg\:\!pr-10 {
    padding-right: 40px !important;
  }

  #body-root .lg\:\!pr-\[70px\] {
    padding-right: 70px !important;
  }

  #body-root .lg\:\!pr-\[75px\] {
    padding-right: 75px !important;
  }

  #body-root .lg\:pb-\[12px\] {
    padding-bottom: 12px;
  }

  #body-root .lg\:pb-\[60px\] {
    padding-bottom: 60px;
  }

  #body-root .lg\:pl-20 {
    padding-left: 80px;
  }

  #body-root .lg\:pt-1\.5 {
    padding-top: 6px;
  }

  #body-root .lg\:\!text-\[10px\] {
    font-size: 10px !important;
  }

  #body-root .lg\:\!text-\[11px\] {
    font-size: 11px !important;
  }

  #body-root .lg\:\!text-\[68px\] {
    font-size: 68px !important;
  }

  #body-root .lg\:text-\[13px\] {
    font-size: 13px;
  }

  #body-root .lg\:text-\[20px\] {
    font-size: 20px;
  }
}

@media (min-width: 992px) {
  @media (min-width: 1200px) {
    #body-root .md\:lg\:aspect-square {
      aspect-ratio: 1 / 1;
    }
  }
}

@media (min-width: 1376px) {
  #body-root .ipadLg\:text-\[14px\] {
    font-size: 14px;
  }
}

@media (min-width: 1600px) {
  #body-root .xl\:-right-20 {
    right: -80px;
  }

  #body-root .xl\:left-\[100px\] {
    left: 100px;
  }

  #body-root .xl\:\!m-0 {
    margin: 0px !important;
  }

  #body-root .xl\:\!ml-\[25px\] {
    margin-left: 25px !important;
  }

  #body-root .xl\:\!mr-\[25px\] {
    margin-right: 25px !important;
  }

  #body-root .xl\:\!mr-\[60px\] {
    margin-right: 60px !important;
  }

  #body-root .xl\:mb-8 {
    margin-bottom: 32px;
  }

  #body-root .xl\:mb-\[44px\] {
    margin-bottom: 44px;
  }

  #body-root .xl\:mr-\[60px\] {
    margin-right: 60px;
  }

  #body-root .xl\:mt-\[44px\] {
    margin-top: 44px;
  }

  #body-root .xl\:\!h-5 {
    height: 20px !important;
  }

  #body-root .xl\:\!h-\[320px\] {
    height: 320px !important;
  }

  #body-root .xl\:\!h-\[48px\] {
    height: 48px !important;
  }

  #body-root .xl\:h-14 {
    height: 56px;
  }

  #body-root .xl\:h-\[75px\] {
    height: 75px;
  }

  #body-root .xl\:h-\[85\%\] {
    height: 85%;
  }

  #body-root .xl\:\!max-h-\[13px\] {
    max-height: 13px !important;
  }

  #body-root .xl\:\!w-10 {
    width: 40px !important;
  }

  #body-root .xl\:\!w-\[320px\] {
    width: 320px !important;
  }

  #body-root .xl\:\!w-\[370px\] {
    width: 370px !important;
  }

  #body-root .xl\:w-\[225px\] {
    width: 225px;
  }

  #body-root .xl\:w-\[25vw\] {
    width: 25vw;
  }

  #body-root .xl\:w-\[280px\] {
    width: 280px;
  }

  #body-root .xl\:w-\[370px\] {
    width: 370px;
  }

  #body-root .xl\:w-\[429px\] {
    width: 429px;
  }

  #body-root .xl\:w-\[498px\] {
    width: 498px;
  }

  #body-root .xl\:w-\[523px\] {
    width: 523px;
  }

  #body-root .xl\:w-\[576px\] {
    width: 576px;
  }

  #body-root .xl\:min-w-\[1200px\] {
    min-width: 1200px;
  }

  #body-root .xl\:\!max-w-\[392px\] {
    max-width: 392px !important;
  }

  #body-root .xl\:\!max-w-\[900px\] {
    max-width: 900px !important;
  }

  #body-root .xl\:max-w-\[714px\] {
    max-width: 714px;
  }

  #body-root .xl\:basis-1\/3 {
    flex-basis: 33.333333%;
  }

  #body-root .xl\:\!gap-x-2 {
    column-gap: 8px !important;
  }

  #body-root .xl\:\!gap-y-1 {
    row-gap: 4px !important;
  }

  #body-root .xl\:gap-y-24 {
    row-gap: 96px;
  }

  #body-root .xl\:\!px-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  #body-root .xl\:\!px-16 {
    padding-left: 64px !important;
    padding-right: 64px !important;
  }

  #body-root .xl\:\!px-20 {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }

  #body-root .xl\:\!px-\[40px\] {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }

  #body-root .xl\:\!px-\[60px\] {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }

  #body-root .xl\:\!px-\[90px\] {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }

  #body-root .xl\:\!py-10 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  #body-root .xl\:\!py-20 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }

  #body-root .xl\:\!py-\[32px\] {
    padding-top: 32px !important;
    padding-bottom: 32px !important;
  }

  #body-root .xl\:px-20 {
    padding-left: 80px;
    padding-right: 80px;
  }

  #body-root .xl\:px-\[23\%\] {
    padding-left: 23%;
    padding-right: 23%;
  }

  #body-root .xl\:px-\[80px\] {
    padding-left: 80px;
    padding-right: 80px;
  }

  #body-root .xl\:px-\[84px\] {
    padding-left: 84px;
    padding-right: 84px;
  }

  #body-root .xl\:px-\[90px\] {
    padding-left: 90px;
    padding-right: 90px;
  }

  #body-root .xl\:py-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  #body-root .xl\:py-\[80px\] {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  #body-root .xl\:\!pb-16 {
    padding-bottom: 64px !important;
  }

  #body-root .xl\:\!pl-0 {
    padding-left: 0px !important;
  }

  #body-root .xl\:\!pl-20 {
    padding-left: 80px !important;
  }

  #body-root .xl\:\!pl-\[75px\] {
    padding-left: 75px !important;
  }

  #body-root .xl\:\!pl-\[88px\] {
    padding-left: 88px !important;
  }

  #body-root .xl\:\!pr-0 {
    padding-right: 0px !important;
  }

  #body-root .xl\:\!pr-20 {
    padding-right: 80px !important;
  }

  #body-root .xl\:\!pr-\[75px\] {
    padding-right: 75px !important;
  }

  #body-root .xl\:\!pr-\[88px\] {
    padding-right: 88px !important;
  }

  #body-root .xl\:pb-20 {
    padding-bottom: 80px;
  }

  #body-root .xl\:pb-\[29\.6px\] {
    padding-bottom: 29.6px;
  }

  #body-root .xl\:pb-\[48px\] {
    padding-bottom: 48px;
  }

  #body-root .xl\:pl-20 {
    padding-left: 80px;
  }

  #body-root .xl\:pl-9 {
    padding-left: 36px;
  }

  #body-root .xl\:pr-20 {
    padding-right: 80px;
  }
}

@media (min-width: 801px) and (max-width: 991px) {
  #body-root .customMdMax\:w-full {
    width: 100%;
  }

  #body-root .customMdMax\:flex-row {
    flex-direction: row;
  }
}

@media (min-width: 768px) and (max-width: 800px) {
  #body-root .customMd\:w-full {
    width: 100%;
  }

  #body-root .customMd\:w-w-458 {
    width: 458px;
  }
}

@media (min-width: 326px) and (max-width: 640px) {
  #body-root .customXs\:my-4 {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  #body-root .customXs\:\!mr-\[16px\] {
    margin-right: 16px !important;
  }

  #body-root .customXs\:mb-4 {
    margin-bottom: 16px;
  }

  #body-root .customXs\:w-full {
    width: 100%;
  }

  #body-root .customXs\:w-w-343 {
    width: 343px;
  }

  #body-root .customXs\:flex-col {
    flex-direction: column;
  }

  #body-root .customXs\:px-4 {
    padding-left: 16px;
    padding-right: 16px;
  }

  #body-root .customXs\:pb-10 {
    padding-bottom: 40px;
  }

  #body-root .customXs\:pb-4 {
    padding-bottom: 16px;
  }

  #body-root .customXs\:pb-5 {
    padding-bottom: 20px;
  }
}

@media print {
  #body-root .print\:hidden {
    display: none;
  }
}

#body-root .\[\&\.MuiGrid-root\.MuiGrid-container\]\:justify-center.MuiGrid-root.MuiGrid-container {
  justify-content: center;
}

#body-root .\[\&\:first-child\]\:\!mt-\[16px\]:first-child {
  margin-top: 16px !important;
}

#body-root .\[\&\:focus-visible\]\:outline:focus-visible {
  outline-style: solid;
}

#body-root .\[\&\:focus-visible\]\:outline-\[1px\]:focus-visible {
  outline-width: 1px;
}

#body-root .\[\&\:focus-visible\]\:outline-\[\#015fcc\]:focus-visible {
  outline-color: #015fcc;
}

#body-root :is(.\[\&\>\*\:first-child\]\:border-0>*:first-child) {
  border-width: 0px;
}

#body-root :is(.\[\&\>\*\:first-child\]\:pt-0>*:first-child) {
  padding-top: 0px;
}

#body-root :is(.\[\&\>\*\:nth-child\(2\)\]\:border-0>*:nth-child(2)) {
  border-width: 0px;
}

#body-root :is(.\[\&\>\.MuiGrid-item\]\:p-2>.MuiGrid-item) {
  padding: 8px;
}

#body-root :is(.\[\&\>\.MuiGrid-item\]\:p-4>.MuiGrid-item) {
  padding: 16px;
}

#body-root :is(.\[\&\>\.MuiGrid-item\]\:p-6>.MuiGrid-item) {
  padding: 24px;
}

#body-root :is(.\[\&\>\.MuiGrid-item\]\:p-8>.MuiGrid-item) {
  padding: 32px;
}

#body-root :is(.\[\&\>MuiBackdrop-root\]\:\!opacity-0>MuiBackdrop-root) {
  opacity: 0 !important;
}

#body-root :is(.\[\&\>a\]\:\!text-black>a) {
  color: rgba(0, 0, 0, 1) !important;
}

#body-root :is(.\[\&\>a\]\:no-underline>a) {
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
}

#body-root :is(.\[\&\>button\]\:flex>button) {
  display: flex;
}

#body-root :is(.\[\&\>button\]\:cursor-pointer>button) {
  cursor: pointer;
}

#body-root :is(.\[\&\>button\]\:flex-col>button) {
  flex-direction: column;
}

#body-root :is(.\[\&\>button\]\:items-center>button) {
  align-items: center;
}

#body-root :is(.\[\&\>button\]\:justify-center>button) {
  justify-content: center;
}

#body-root :is(.\[\&\>button\]\:border>button) {
  border-width: 1px;
}

#body-root :is(.\[\&\>button\]\:border-grey-200>button) {
  --tw-border-opacity: 1;
  border-color: rgb(230 230 230 / var(--tw-border-opacity));
}

#body-root :is(.\[\&\>button\]\:bg-white>button) {
  background-color: rgba(255, 255, 255, 1);
}

#body-root :is(.\[\&\>button\]\:p-\[6\.5px\]>button) {
  padding: 6.5px;
}

#body-root :is(.\[\&\>div\.sku-info\:first-child\]\:mt-5>div.sku-info:first-child) {
  margin-top: 20px;
}

#body-root :is(.\[\&\>div\>div\]\:float-left>div>div) {
  float: left;
}

#body-root :is(.\[\&\>div\>fieldset\]\:\!border-black>div>fieldset) {
  border-color: rgba(0, 0, 0, 1) !important;
}

#body-root :is(.\[\&\>div\]\:\!left-0>div) {
  left: 0px !important;
}

#body-root :is(.\[\&\>div\]\:\!left-\[5px\]>div) {
  left: 5px !important;
}

#body-root :is(.\[\&\>div\]\:\!right-0>div) {
  right: 0px !important;
}

#body-root :is(.\[\&\>div\]\:\!right-\[5px\]>div) {
  right: 5px !important;
}

#body-root :is(.\[\&\>div\]\:grid>div) {
  display: grid;
}

#body-root :is(.\[\&\>div\]\:\!w-10>div) {
  width: 40px !important;
}

#body-root :is(.\[\&\>div\]\:border-\[0\.75px\]>div) {
  border-width: 0.75px;
}

#body-root :is(.\[\&\>div\]\:border-rh-dark>div) {
  --tw-border-opacity: 1;
  border-color: rgb(137 136 134 / var(--tw-border-opacity));
}

#body-root :is(.\[\&\>div\]\:p-0>div) {
  padding: 0px;
}

#body-root :is(.\[\&\>h5\]\:leading-\[1\]>h5) {
  line-height: 1;
}

#body-root :is(.\[\&\>img\]\:block>img) {
  display: block;
}

#body-root :is(.\[\&\>img\]\:hidden>img) {
  display: none;
}

#body-root :is(.\[\&\>p\]\:mb-\[8px\]>p) {
  margin-bottom: 8px;
}

#body-root :is(.\[\&\>p\]\:mt-0>p) {
  margin-top: 0px;
}

#body-root :is(.\[\&\>p\]\:\!font-rhsans>p) {
  font-family: RHSans !important;
}

#body-root :is(.\[\&\>p\]\:font-rhsans>p) {
  font-family: RHSans;
}

#body-root :is(.\[\&\>p\]\:\!text-11px>p) {
  font-size: 11px !important;
}

#body-root :is(.\[\&\>p\]\:text-11px>p) {
  font-size: 11px;
}

#body-root :is(.\[\&\>p\]\:\!font-light>p) {
  font-weight: 300 !important;
}

#body-root :is(.\[\&\>p\]\:\!uppercase>p) {
  text-transform: uppercase !important;
}

#body-root :is(.\[\&\>p\]\:leading-\[12\.1px\]>p) {
  line-height: 12.1px;
}

#body-root :is(.\[\&\>p\]\:tracking-\[0\.44px\]>p) {
  letter-spacing: 0.44px;
}

#body-root :is(.\[\&\>p\]\:\!text-\[\#CA2022\]>p) {
  --tw-text-opacity: 1 !important;
  color: rgb(202 32 34 / var(--tw-text-opacity)) !important;
}

@media (min-width: 768px) {
  #body-root :is(.sm\:\[\&\>p\]\:mb-\[13px\]>p) {
    margin-bottom: 13px;
  }
}

#body-root :is(.\[\&\>span\>svg\]\:h-\[18px\]>span>svg) {
  height: 18px;
}

#body-root :is(.\[\&\>span\>svg\]\:h-\[20px\]>span>svg) {
  height: 20px;
}

#body-root :is(.\[\&\>span\>svg\]\:w-\[18px\]>span>svg) {
  width: 18px;
}

#body-root :is(.\[\&\>span\>svg\]\:w-\[20px\]>span>svg) {
  width: 20px;
}

#body-root :is(.\[\&\>span\]\:\!text-black>span) {
  color: rgba(0, 0, 0, 1) !important;
}

#body-root :is(.\[\&\>strong\]\:font-primary-rhsans>strong) {
  font-family: RHSans, Helvetica, Arial;
}

#body-root :is(.\[\&\>strong\]\:text-\[1rem\]>strong) {
  font-size: 1rem;
}

#body-root :is(.\[\&\>strong\]\:font-medium>strong) {
  font-weight: 500;
}

#body-root :is(.\[\&\>strong\]\:uppercase>strong) {
  text-transform: uppercase;
}

#body-root :is(.\[\&\>strong\]\:text-black>strong) {
  color: rgba(0, 0, 0, 1);
}

#body-root :is(.\[\&\>svg\]\:inset-auto>svg) {
  inset: auto;
}

#body-root :is(.\[\&\>svg\]\:\!h-4>svg) {
  height: 16px !important;
}

#body-root :is(.\[\&\>svg\]\:\!h-\[11px\]>svg) {
  height: 11px !important;
}

#body-root :is(.\[\&\>svg\]\:\!h-\[13px\]>svg) {
  height: 13px !important;
}

#body-root :is(.\[\&\>svg\]\:\!h-\[16px\]>svg) {
  height: 16px !important;
}

#body-root :is(.\[\&\>svg\]\:h-\[11px\]>svg) {
  height: 11px;
}

#body-root :is(.\[\&\>svg\]\:h-\[16px\]>svg) {
  height: 16px;
}

#body-root :is(.\[\&\>svg\]\:\!w-4>svg) {
  width: 16px !important;
}

#body-root :is(.\[\&\>svg\]\:\!w-\[11px\]>svg) {
  width: 11px !important;
}

#body-root :is(.\[\&\>svg\]\:\!w-\[13px\]>svg) {
  width: 13px !important;
}

#body-root :is(.\[\&\>svg\]\:\!w-\[16px\]>svg) {
  width: 16px !important;
}

#body-root :is(.\[\&\>svg\]\:w-\[11px\]>svg) {
  width: 11px;
}

#body-root :is(.\[\&\>svg\]\:w-\[16px\]>svg) {
  width: 16px;
}

#body-root :is(.\[\&\>svg\]\:\!text-\[11px\]>svg) {
  font-size: 11px !important;
}

#body-root :is(.\[\&\>svg\]\:\!text-\[13px\]>svg) {
  font-size: 13px !important;
}

#body-root :is(.\[\&\>svg\]\:\!text-\[16px\]>svg) {
  font-size: 16px !important;
}

#body-root :is(.\[\&\>svg\]\:text-\[11px\]>svg) {
  font-size: 11px;
}

#body-root :is(.\[\&\>svg\]\:text-\[16px\]>svg) {
  font-size: 16px;
}

@media (min-width: 768px) {
  #body-root :is(.sm\:\[\&_\.MuiDialogContent-root\]\:p-\[88px\] .MuiDialogContent-root) {
    padding: 88px;
  }
}

@media (min-width: 1600px) {
  #body-root :is(.xl\:\[\&_\.MuiDialogContent-root\]\:p-\[128px\] .MuiDialogContent-root) {
    padding: 128px;
  }
}

#body-root :is(.\[\&_a\]\:font-normal a) {
  font-weight: 400;
}

#body-root :is(.\[\&_a\]\:text-\[\#666\] a) {
  --tw-text-opacity: 1;
  color: rgb(102 102 102 / var(--tw-text-opacity));
}

#body-root :is(.\[\&_a\]\:underline a) {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}

#body-root :is(.\[\&_a\]\:\[text-decoration\:none\] a) {
  text-decoration: none;
}

#body-root :is(.\[\&_div\]\:mb-1\.5 div) {
  margin-bottom: 6px;
}

#body-root :is(.\[\&_div\]\:first\:my-1\.5:first-child div) {
  margin-top: 6px;
  margin-bottom: 6px;
}

#body-root :is(.\[\&_svg\]\:pointer-events-none svg) {
  pointer-events: none;
}

#body-root :is(.\[\&_svg\]\:size-4 svg) {
  width: 16px;
  height: 16px;
}

#body-root :is(.\[\&_svg\]\:shrink-0 svg) {
  flex-shrink: 0;
}

#body-root .\[\&b\]\:text-\[13px\]b {
  font-size: 13px;
}

#body-root .\[\&b\]\:font-normalb {
  font-weight: 400;
}

#body-root .\[\&b\]\:uppercaseb {
  text-transform: uppercase;
}

#body-root .\[\&b\]\:leading-5b {
  line-height: 20px;
}

#body-root .\[\&b\]\:leading-\[1\.66rem\]b {
  line-height: 1.66rem;
}

#body-root .\[\&b\]\:tracking-\[0\.025em\]b {
  letter-spacing: 0.025em;
}

#body-root .\[\&b\]\:text-blackb {
  color: rgba(0, 0, 0, 1);
}

#body-root .\[\&b\]\:no-underlineb {
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
}

#body-root .\[\&b\]\:hover\:text-black:hoverb {
  color: rgba(0, 0, 0, 1);
}
